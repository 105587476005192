import { Flex } from "antd";
import styles from "./Message.module.scss";
import { FeedbackCard } from "./FeedbackCard";
import { FC, useMemo } from "react";
import { FeedbackTypeEnum } from "types/enum";
import { CloseOutlined } from "@ant-design/icons";

type FeedbackGroupProps = {
  feedbacks: FeedbackData[];
  showFeedback: boolean;
  feedbackType: FeedbackTypeEnum | undefined;
  messageId: MessageId;
  setShowFeedback: () => void;
  user: FeedbackUser;
  conversation: FeedbackConversation;
};

export const FeedbackGroup: FC<FeedbackGroupProps> = ({
  feedbacks,
  showFeedback,
  feedbackType,
  messageId,
  setShowFeedback,
  user,
  conversation,
}) => {
  const userFeedback = feedbacks?.find(
    (feedback) => feedback.user?.id === user?.id,
  );

  const newFeedback: FeedbackData = {
    conversation: {
      id: conversation?.id,
      name: conversation?.name as string,
      description: conversation?.description as string,
    },
    sentiment: [],
    commentSet: userFeedback?.commentSet || [],
    user: user as FeedbackUser,
    message: messageId as MessageId,
    ...(userFeedback?.id && { id: userFeedback?.id }),
  };

  const feedbacksByType = useMemo(() => {
    return feedbackType
      ? userFeedback === undefined
        ? [newFeedback, ...feedbacks!]
        : feedbacks.sort(
            (a, b) =>
              new Date(b.createdAt as string).getTime() -
              new Date(a.createdAt as string).getTime(),
          )
      : [];
  }, [feedbackType, userFeedback]);

  const previousLabels = userFeedback?.sentiment.flatMap((sentiment) =>
    sentiment.labelSet.map((label) => label.id),
  );

  return (
    <Flex vertical>
      {showFeedback && (
        <>
          <CloseOutlined
            className={styles.close}
            onClick={() => {
              setShowFeedback();
            }}
          />
          {feedbacksByType.map((fb) => {
            const currentSentiment = fb?.sentiment?.find(
              (sentiment) => sentiment.name === feedbackType,
            );

            return (
              (currentSentiment !== undefined || fb?.user?.id === user?.id) && (
                <FeedbackCard
                  showFeedbackCard={showFeedback}
                  setShowFeedbackCard={setShowFeedback}
                  feedbackType={feedbackType}
                  messageId={messageId}
                  feedbackId={fb.id as unknown as FeedbackId}
                  previousLabels={previousLabels || []}
                  user={user}
                  currentSentiment={currentSentiment}
                />
              )
            );
          })}
        </>
      )}
    </Flex>
  );
};
