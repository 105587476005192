import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from "react";
import { memoriesReducer } from "reducers/memories";
import { NewtonApi } from "utils/newtonApi";
import { useDataProviderContext } from "./DataProviderContext";

type MemoriesContextType = {
  createMemory: (memory: NewMemory) => void;
  refreshMemories: () => void;
  labels: MemoryLabel[];
  loading: boolean;
  memories: Memory[];
  sources: MemorySource[];
  scopes: MemoryScope[];
  types: MemoryType[];
};

const MemoriesContext = createContext<MemoriesContextType>(
  {} as MemoriesContextType,
);

export const MemoriesContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { authenticated, me } = useDataProviderContext();
  const [state, dispatch] = useReducer(memoriesReducer, {
    labels: [],
    loading: false,
    memories: [],
    sources: [],
    types: [],
  });

  const scopes: MemoryScope[] = [
    {
      name: "Conversation",
      value: "conversation",
    },
    {
      name: "Organization",
      value: "organization",
    },
    {
      name: "User Defined",
      value: "user-defined",
    },
  ];

  useEffect(() => {
    (async () => {
      if (!authenticated) return;

      dispatch({
        type: "SET_LOADING",
        payload: true,
      });
      const [memories, types, labels, sources] = await Promise.all([
        NewtonApi.fetchMemories(),
        NewtonApi.fetchMemoryTypes(),
        NewtonApi.fetchMemoryLabels(),
        NewtonApi.fetchMemorySources(),
      ]);

      dispatch({
        type: "FETCHED_MEMORIES",
        payload: memories.results,
      });

      dispatch({
        type: "FETCHED_MEMORY_LABELS",
        payload: labels.results,
      });

      dispatch({
        type: "FETCHED_MEMORY_SOURCES",
        payload: sources.results,
      });

      dispatch({
        type: "FETCHED_MEMORY_TYPES",
        payload: types.results,
      });
      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
    })();
  }, [authenticated]);

  const createMemory = useCallback(
    async (memory: NewMemory) => {
      await NewtonApi.createMemory({
        ...memory,
        owner: me?.id,
      });
    },
    [me],
  );

  const refreshMemories = useCallback(async () => {
    dispatch({
      type: "SET_LOADING",
      payload: true,
    });
    const memories = await NewtonApi.fetchMemories();
    dispatch({
      type: "FETCHED_MEMORIES",
      payload: memories.results,
    });
    dispatch({
      type: "SET_LOADING",
      payload: false,
    });
  }, []);

  return (
    <MemoriesContext.Provider
      value={{
        ...state,
        createMemory,
        refreshMemories,
        scopes,
      }}
    >
      {children}
    </MemoriesContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useMemoriesContext = () => {
  const context = useContext(MemoriesContext);
  if (!context) {
    throw new Error(
      "useMemoriesContext must be used within a MemoriesContextProvider",
    );
  }
  return context;
};
