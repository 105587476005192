import "./App.scss";

import { ConfigProvider, Layout } from "antd";
import { Outlet, useLocation } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";

import { ApiError } from "./utils/newtonApi";
import { ConversationContextProvider } from "contexts/ConversationContext";
import { DataProviderContextProvider } from "contexts/DataProviderContext";
import { ErrorNotification } from "./components/error-notification/ErrorNotification";
import { MainMenu } from "components/mainmenu/MainMenu";
import { NewtonApi } from "utils/newtonApi";
import { MemoriesContextProvider } from "contexts/MemoriesContext";
import { FeedbackContextProvider } from "contexts/FeedbackContext";

export const App: React.FC = () => {
  const [errors, setErrors] = useState<ApiError[]>([]);

  const location = useLocation();

  let className = "nri__page";
  // FIXME: make this more robust
  if (location.pathname.includes("/c")) {
    className = "conversation";
  }

  const onDismiss = useCallback((error: ApiError) => {
    setErrors((data) => {
      return data.filter((e) => e !== error);
    });
  }, []);

  useEffect(() => {
    const timeout = setInterval(() => {
      if (NewtonApi.errors.length === 0) return;
      setErrors(NewtonApi.errors);
      NewtonApi.clearErrors();
    }, 500);

    return () => clearInterval(timeout);
  }, []);

  return (
    <ConfigProvider
      theme={{
        components: {
          Alert: {
            colorWarningBorder: "#FFC470",
            colorWarningBg: "rgba(255, 196, 112, 0.12)",
          },
          Button: {
            defaultBorderColor: "#695AD9",
            defaultColor: "#695AD9",
            contentFontSizeLG: 14,
            controlHeight: 44,
            fontWeight: 500,
            paddingInline: "20px",
            primaryShadow: "none",
          },
          Form: {
            labelColor: "#41205A",
          },
          Input: {
            borderRadius: 4,
            colorBorder: "#F5F5F5",
            controlHeight: 40,
          },
          // Menu: {
          //   itemSelectedBg: "#463e6b",
          // },
          Layout: { headerBg: "transparent" },
          Modal: {
            colorBgMask: "rgba(145, 140, 166, 0.3)",
            padding: 80,
            titleFontSize: 24,
            titleLineHeight: 1.3,
          },
          Select: {
            borderRadius: 4,
            colorBorder: "#F5F5F5",
            controlHeight: 40,
          },
          Tabs: {
            itemSelectedColor: "var(--nri-color-primary-vibrant)",
            inkBarColor: "var(--nri-color-primary-vibrant)",
          },
        },
        token: {
          fontFamily: "var(--nri-font)",
          colorPrimary: "#695AD9",
          colorTextBase: "var(--nri-text-color)",
          colorText: "var(--nri-text-color)",
        },
      }}
    >
      <DataProviderContextProvider>
        {/**
         * FIXME: We need to break the ConversationContextProvider into two. The one here will hande the user's state of
         * conversations that are in progress. The internal one (in ConversationsView) will be unique to a conversation and
         * should not contain any information that needs to persist between switching conversation pages.
         */}
        <ConversationContextProvider>
          <MemoriesContextProvider>
            <FeedbackContextProvider>
              <Layout
                className={className}
                hasSider
                style={{ height: "100vh" }}
              >
                <MainMenu />
                <Outlet />
                <ErrorNotification errors={errors} onDismiss={onDismiss} />
              </Layout>
            </FeedbackContextProvider>
          </MemoriesContextProvider>
        </ConversationContextProvider>
      </DataProviderContextProvider>
    </ConfigProvider>
  );
};

export default App;
