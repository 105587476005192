import { useAuth0 } from "@auth0/auth0-react";
import { Layout } from "antd";
import { Loader } from "components/loader/Loader";
import { useDataProviderContext } from "contexts/DataProviderContext";
import { PropsWithChildren, useEffect, useMemo, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { NewtonApi } from "utils/newtonApi";

const ProtectedRoute: React.FC<PropsWithChildren> = ({ children }) => {
  const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();
  const [successfulLoad, setSuccessfulLoad] = useState<boolean | null>(null);
  const { setAuthenticated, isLoading: dataLoading } = useDataProviderContext();

  useEffect(() => {
    NewtonApi.fetchToken = getAccessTokenSilently;
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (isAuthenticated && !isLoading) {
      (async () => {
        try {
          await getAccessTokenSilently();
          await NewtonApi.fetchUser();
          setAuthenticated(true);

          setSuccessfulLoad(true);
        } catch (e) {
          setSuccessfulLoad(false);
          console.error(e);
        }
      })();
    }
  }, [isAuthenticated, isLoading]);

  const isValidUser = useMemo(
    () => isAuthenticated && !isLoading && successfulLoad,
    [successfulLoad, isAuthenticated, isLoading],
  );

  if (isLoading) {
    return (
      <Layout
        style={{
          alignContent: "center",
          height: "100vh",
          justifyContent: "center",
        }}
      >
        <Loader fullscreen spinning />
      </Layout>
    );
  }

  if (successfulLoad === false) {
    return <Navigate to="/unauthorized" />;
  }

  if (isValidUser) {
    return <>{children || <Outlet />}</>;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return (
    <Layout
      style={{
        alignContent: "center",
        background: "white",
        height: "100vh",
        justifyContent: "center",
      }}
    >
      <Loader fullscreen spinning={dataLoading} />
    </Layout>
  );
};

export default ProtectedRoute;
