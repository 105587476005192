import { Crepe } from "@milkdown/crepe";
import { MilkdownProvider } from "@milkdown/react";
import React, { useEffect, useRef } from "react";
import styles from "./MarkdownEditor.module.scss";
import { listener, listenerCtx } from "@milkdown/kit/plugin/listener";

import "@milkdown/crepe/theme/common/style.css";
import "@milkdown/crepe/theme/nord.css";

type Props = {
  initialContent: string;
  onChange: (content: string) => void;
};

export const MarkdownEditor: React.FC<Props> = ({
  initialContent,
  onChange,
}) => {
  const wrapperDiv = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const crepe = new Crepe({
      defaultValue: initialContent,
      // features: { [Crepe.Feature.ImageBlock]: false },
      featureConfigs: {
        [Crepe.Feature.ImageBlock]: {
          blockUploadButton: () => null,
          inlineUploadButton: () => null,
          blockUploadPlaceholderText: "Paste image URL",
          inlineUploadPlaceholderText: "Paste image URL",
        },
      },
      root: "#mdown",
    });

    const createCrepe = async () => {
      crepe.editor
        .config((ctx) => {
          ctx.get(listenerCtx).markdownUpdated((_ctx, markdown) => {
            onChange(markdown);
          });
        })
        .use(listener);

      await crepe.create();
    };

    createCrepe();

    return () => {
      crepe.destroy();
    };
  }, []);

  return (
    <MilkdownProvider>
      <div className={styles.editor} id="mdown" ref={wrapperDiv} />
    </MilkdownProvider>
  );
};
