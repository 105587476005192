import { useList } from "hooks/useList";
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDataProviderContext } from "./DataProviderContext";
import { useLocation } from "react-router-dom";

type ConversationContextType = {
  activeMessage?: Message;
  selectedAnalyst: Analyst;
  selectedDataSources: DataSource[];
  addDatasource: boolean;
  setAddDatasource: (show: boolean) => void;
  selectedCSV: { csv: File | null; displayObjectId: number | null };
  setSelectedCSV: ({
    csv,
    displayObjectId,
  }: {
    csv: File | null;
    displayObjectId: number | null;
  }) => void;
  createMessage: (message: Message) => void;
  clear: () => void;
  clearSelectedDataSources: () => void;
  isAnalystSelected: (analyst: Analyst) => boolean;
  isDataSourceSelected: (dataSource: DataSource) => boolean;
  isStopping: boolean;
  restoreMessage: (message: Partial<Message>) => void;
  stopAnalyst: () => Promise<void>;
  toggleSelectedAnalyst: (analyst: Analyst) => void;
  toggleSelectedDataSource: (dataSource: DataSource) => void;
  updateMessage: (message?: Partial<Message>) => void;
  selectedLabels: SentimentLabelSet[];
  clearSelectedLabels: () => void;
  toggleSelectedLabel: (sentiment: SentimentLabelSet) => void;
  isLabelSelected: (sentiment: SentimentLabelSet) => boolean;
};

const ConversationContext = createContext<ConversationContextType>(
  {} as ConversationContextType,
);

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const ConversationContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  // FIXME: setting these convo prompts for all conversations should be
  // moved up a level. The ConversationContext should be specific to a single conversation
  const [convoPrompts, setConvoPrompts] = useState<{
    [key: ConversationId]: Message;
  }>({});
  const [sessionId, setSessionId] = useState<string>();
  const [isStopping, setIsStopping] = useState(false);
  const [addDatasource, setAddDatasource] = useState<boolean>(false);
  const [selectedCSV, setSelectedCSV] = useState<{
    csv: File | null;
    displayObjectId: number | null;
  }>({
    csv: null,
    displayObjectId: null,
  });

  const query = useQuery();
  const params = Object.fromEntries(query.entries());

  const {
    activeConversation,
    activeAnalyst,
    analysts,
    iceBreakers,
    isLoading,
    datasources: rawDataSources,
    newMessage,
    stopConversation,
    cleanConversationDataSources,
    setCleanConversationDataSources,
  } = useDataProviderContext();

  const selectedIceBreaker = useMemo(() => {
    return iceBreakers.find((ib) => String(ib.id) == params.icebreaker);
  }, [iceBreakers, params.icebreaker]);

  // If we have an icebreaker set the data sources and analyst
  useEffect(() => {
    if (selectedIceBreaker) {
      selectedIceBreaker.dataSources.forEach((ds) => {
        toggleSelectedDataSource(
          rawDataSources.find((rds) => rds.id == ds.id)!,
        );
      });
    }
  }, [selectedIceBreaker]);

  const [
    selectedDataSources,
    {
      clear: clearSelectedDataSources,
      toggle: toggleSelectedDataSource,
      inList: isDataSourceSelected,
    },
  ] = useList<DataSource>();

  const [
    selectedAnalyst,
    {
      clear: clearSelectedAnalyst,
      toggle: toggleSelectedAnalyst,
      inList: isAnalystSelected,
    },
  ] = useList<Analyst>({ singleSelect: true });

  const [
    selectedLabels,
    {
      clear: clearSelectedLabels,
      toggle: toggleSelectedLabel,
      inList: isLabelSelected,
    },
  ] = useList<SentimentLabelSet>();

  const convoId = useMemo(() => {
    return activeConversation?.id || ("default" as ConversationId);
  }, [activeConversation?.id]);

  const restoreMessage = useCallback(
    (message: Partial<Message>) => {
      const { analystId = undefined, dataSources = [] } = message;
      const analyst = analysts.find((a) => a.id == analystId) || activeAnalyst;

      setConvoPrompts((prev) => ({
        ...prev,
        [message.conversationId!]: message,
      }));

      if (analyst) {
        toggleSelectedAnalyst(analyst);
      }
      dataSources.forEach((ds) => {
        toggleSelectedDataSource(
          rawDataSources.find((rds) => rds.id == ds.id)!,
        );
      });
    },
    [
      activeAnalyst,
      analysts,
      rawDataSources,
      setConvoPrompts,
      toggleSelectedAnalyst,
      toggleSelectedDataSource,
    ],
  );

  // Create shallow message on convo selection
  useEffect(() => {
    if (isLoading) return;
    const hasDefault = !!convoPrompts["default" as ConversationId];
    if (hasDefault || selectedIceBreaker) {
      setConvoPrompts((prev) => {
        let data = { ...convoPrompts["default" as ConversationId] };
        if (selectedIceBreaker) {
          data = {
            ...data,
            analystId: undefined,
            text: selectedIceBreaker.content,
            dataSources: selectedIceBreaker.dataSources,
          };
        } else {
          data = { ...data, text: "" };
        }
        return {
          ...prev,
          [convoId]: {
            ...data,
            conversationId: convoId,
          },
        };
      });
    } else {
      if (activeConversation) {
        clearSelectedAnalyst();
        clearSelectedDataSources();
        restoreMessage(
          convoPrompts[convoId] || {
            conversationId: convoId,
            analystId: undefined,
            datasources: [],
            text: "",
          },
        );
      }
    }
  }, [isLoading, convoId, selectedIceBreaker]);

  const activeMessage = useMemo(() => {
    return convoPrompts[convoId];
  }, [convoPrompts, convoId]);

  const updateMessage = useCallback(
    (message?: Partial<Message>) => {
      if (message) {
        setConvoPrompts((prev) => {
          return {
            ...prev,
            [convoId]: {
              ...(prev[convoId] || {}),
              ...message,
            },
          };
        });
      } else {
        setConvoPrompts((prev) => {
          delete prev[convoId];
          return {
            ...prev,
            [convoId]: {
              analystId: undefined,
              conversationId: convoId,
              text: "",
              datasources: [],
            },
          };
        });
      }
    },
    [activeConversation, convoId, setConvoPrompts],
  );

  // Update analyst
  useEffect(() => {
    if (activeConversation?.id == activeMessage?.conversationId) {
      updateMessage({
        analystId: selectedAnalyst[0]?.id,
      });
    }
  }, [
    activeMessage?.id,
    activeConversation?.id,
    selectedAnalyst,
    updateMessage,
  ]);

  // Update datasources
  useEffect(() => {
    if (activeConversation?.id == activeMessage?.conversationId) {
      updateMessage({
        dataSources: selectedDataSources,
      });
    }
  }, [
    convoId,
    activeConversation?.id,
    activeMessage?.conversationId,
    selectedDataSources,
  ]);

  const createMessage = useCallback(
    async (message: Message) => {
      const response = await newMessage({
        ...message,
        iceBreakerId: selectedIceBreaker?.id,
      });
      setSessionId(response.sessionId);
      clearSelectedDataSources();
      updateMessage(undefined);
    },
    [clearSelectedDataSources, newMessage, selectedIceBreaker?.id],
  );

  const stopAnalyst = useCallback(async () => {
    setIsStopping(true);
    if (!sessionId) {
      console.error("Can not stop conversation without session id.");
      return;
    }
    await stopConversation(sessionId);
    // FIXME: this is practically instant. How should we really check?
    setTimeout(() => setIsStopping(false), 1000);
  }, [sessionId, stopConversation]);

  const clear = useCallback(() => {
    clearSelectedAnalyst();
    clearSelectedDataSources();
  }, [clearSelectedAnalyst, clearSelectedDataSources]);

  useEffect(() => {
    if (cleanConversationDataSources) {
      clearSelectedDataSources();
      setCleanConversationDataSources(false);
    }
  }, [cleanConversationDataSources]);

  return (
    <ConversationContext.Provider
      value={{
        clear,
        clearSelectedDataSources,
        clearSelectedLabels,
        addDatasource,
        activeMessage,
        selectedAnalyst: selectedAnalyst[0],
        selectedDataSources,
        selectedLabels,
        createMessage,
        isAnalystSelected,
        isDataSourceSelected,
        isLabelSelected,
        isStopping,
        selectedCSV,
        restoreMessage,
        stopAnalyst,
        toggleSelectedAnalyst,
        toggleSelectedDataSource,
        toggleSelectedLabel,
        updateMessage,
        setAddDatasource,
        setSelectedCSV,
      }}
    >
      {children}
    </ConversationContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useConversationContext = () => {
  const context = useContext(ConversationContext);
  if (!context) {
    throw new Error(
      "useConversationContext must be used within a ConversationContextProvider",
    );
  }
  return context;
};
