import { Flex, Menu, MenuProps, Typography } from "antd";

import ArchiveIcon from "../../assets/icons/archive.svg?react";
import { ConversationModal } from "components/conversationmodal/ConversationModal";
import CopyIcon from "../../assets/icons/copy.svg?react";
import React from "react";
import { ShareConversationModal } from "components/share-conversation-modal/ShareConversationModal";
import ShareHandIcon from "../../assets/icons/shareHand.svg?react";
import { SidebarItemActionDropdown } from "./SidebarItemActionDropdown";
import TrashIcon from "../../assets/icons/trash.svg?react";
import { cx } from "../../utils/cx";
import { motion } from "framer-motion";
import styles from "./Sidebar.module.scss";
import { useDataProviderContext } from "contexts/DataProviderContext";
import { ConfirmationModal } from "components/common/ConfirmationModal";
import { TimeAgo } from "../timeago/TimeAgo";

type Props = {
  active: boolean;
  conversation: Conversation;
  // @TODO: Move to context, not doing atm to avoid conflicts
  conversationFilter?: string;
  disabled?: boolean;
  index: number;
  onSelect(conv: Conversation): void;
  key: string;
};

export const SidebarItem: React.FC<Props> = ({
  active,
  conversation,
  disabled,
  onSelect,
}) => {
  const [showModal, setShowModal] = React.useState(false);
  const [showShareModal, setShowShareModal] = React.useState(false);
  const [confirmationModal, setConfirmationModal] = React.useState(false);
  const [showEdit, setShowEdit] = React.useState(false);
  const [modalAction, setModalAction] = React.useState<string | null>(null);
  const {
    copyConversation,
    getUnreadMessages,
    archiveConversation,
    deleteConversation,
  } = useDataProviderContext();
  const unreadMessages = getUnreadMessages(conversation.id);
  const key = conversation.id;
  const texts: { [key: string]: { title: string; description: string } } = {
    archive: {
      title: "Archive chat",
      description: "Are you sure you want to archive this conversation?",
    },
    delete: {
      title: "Delete chat",
      description: "Are you sure you want to delete this conversation?",
    },
  };

  const items: MenuProps["items"] = [
    {
      label: "Manage access",
      key: "0",
      icon: (
        <ShareHandIcon
          style={{ color: "#918CA6" }}
          width={"16px"}
          height={"16px"}
        />
      ),
      onClick: () => {
        setShowShareModal((open) => !open);
      },
    },
    {
      label: "Archive",
      key: "1",
      icon: (
        <ArchiveIcon
          style={{ color: "#918CA6" }}
          width={"16px"}
          height={"16px"}
        />
      ),
      onClick: () => {
        setModalAction("archive");
        setConfirmationModal((prev) => !prev);
      },
    },

    {
      label: "Copy",
      key: "3",
      icon: (
        <CopyIcon style={{ color: "#918CA6" }} width={"16px"} height={"16px"} />
      ),
      onClick: () => copyConversation(conversation.id),
    },
    {
      label: "Delete",
      key: "4",
      icon: (
        <TrashIcon
          style={{ color: "#918CA6" }}
          width={"16px"}
          height={"16px"}
        />
      ),
      onClick: () => {
        setConfirmationModal((prev) => !prev);
        setModalAction("delete");
      },
    },
  ];

  const handleConfirm = () => {
    if (modalAction === "archive") {
      archiveConversation(conversation);
    } else if (modalAction === "delete") {
      deleteConversation(conversation);
    }
    setConfirmationModal(false);
  };

  return (
    <motion.li
      key={key}
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, x: -20 }}
      layout
      transition={{ duration: 0.2, ease: "easeInOut" }}
    >
      {showShareModal && (
        <ShareConversationModal
          conversation={conversation}
          isOpen
          onClose={() => setShowShareModal(false)}
        />
      )}
      <ConversationModal
        conversation={conversation}
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        onOk={() => setShowModal(false)}
      />
      <ConfirmationModal
        isOpen={confirmationModal}
        title={modalAction ? texts[modalAction].title : ""}
        description={modalAction ? texts[modalAction].description : ""}
        onCancel={() => setConfirmationModal(false)}
        onConfirm={handleConfirm}
        confirmColor={modalAction === "delete" ? "#E23C26" : "#695AD9"}
      />
      <Menu.Item
        className={cx(styles.sidebarItem, { [styles.active]: active })}
        disabled={disabled}
        onClick={() => {
          if (!disabled) {
            onSelect(conversation);
          }
        }}
        onMouseOut={() => setShowEdit(false)}
        onMouseOver={() => setShowEdit(true)}
        key={key}
      >
        <Flex justify="space-between" align="center">
          <div className={styles.pill}>
            <Typography.Text className={styles.conversationName}>
              {conversation.name}
            </Typography.Text>
          </div>
          <div
            className={styles.right}
            style={showEdit ? { right: "6px" } : { right: "16px" }}
          >
            {showEdit ? (
              <SidebarItemActionDropdown items={items} />
            ) : unreadMessages.length > 0 ? (
              unreadMessages.length
            ) : (
              <TimeAgo date={conversation.updatedAt} />
            )}
          </div>
        </Flex>
      </Menu.Item>
    </motion.li>
  );
};
