import {
  Button,
  Card,
  Flex,
  Form,
  Input,
  Select,
  Skeleton,
  Space,
  Tag,
} from "antd";
import DeleteIcon from "assets/icons/trash.svg?react";
import { Loader } from "components/loader/Loader";
import { MemoryModal } from "components/memory-modal/MemoryModal";
import { useDataProviderContext } from "contexts/DataProviderContext";
import { useMemoriesContext } from "contexts/MemoriesContext";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import styles from "./TemplateForm.module.scss";
import { NewtonApi } from "utils/newtonApi";
import { MarkdownEditor } from "components/markdown-editor/MarkdownEditor";

type TemplateFormCardProps = {
  title: string;
  children?: React.ReactNode;
};

const TemplateFormCard = ({ children, title }: TemplateFormCardProps) => (
  <Card
    bordered={false}
    className={styles.card}
    classNames={{ body: styles.cardBody }}
  >
    <Card.Grid className={styles.title}>{title}</Card.Grid>
    <Card.Grid style={{ boxShadow: "none", flex: 1 }}>{children}</Card.Grid>
  </Card>
);

export interface TemplateFormHandles {
  save: () => void;
}

export const TemplateForm = forwardRef<TemplateFormHandles>(
  (_props: unknown, ref) => {
    const { templateId } = useParams();
    const [newReferenceConvo, setNewReferenceConvo] = useState<
      Conversation | undefined
    >();
    const [conversationDatasources, setConversationDatasources] = useState<
      MessageDataSources[]
    >([]);
    const [isModalOpen, setModalOpen] = useState(false);
    const [useMarkdownEditor, setMarkdownEditor] = useState(true);
    const navigate = useNavigate();

    const {
      analysts,
      createIceBreaker,
      datasources: allDatasources,
      iceBreakers,
      updateIceBreaker,
    } = useDataProviderContext();

    const {
      loading: memoriesLoading,
      memories,
      refreshMemories,
    } = useMemoriesContext();
    const existingIceBreaker = iceBreakers.find(
      (ib) => `${ib.id}` === templateId,
    );

    // Clone from conversation logic
    const [generatingContent, setGeneratingContent] = useState(false);
    const [searchParams] = useSearchParams();
    const conversationId = searchParams.get("conversationId") as ConversationId;

    const shouldGenerateWorkflowPlan = !!conversationId;

    useEffect(() => {
      (async () => {
        if (shouldGenerateWorkflowPlan) {
          const [conversation, dataSources] = await Promise.all([
            NewtonApi.fetchConversation(conversationId),
            NewtonApi.fetchConversationDataSources(conversationId),
          ]);
          setNewReferenceConvo(conversation);
          setConversationDatasources(dataSources);
        }
      })();
    }, [shouldGenerateWorkflowPlan, conversationId]);

    const referenceConvo = shouldGenerateWorkflowPlan
      ? newReferenceConvo
      : undefined;

    const { name: placeholderName, description: placeholderDescription } =
      shouldGenerateWorkflowPlan && referenceConvo
        ? referenceConvo
        : {
            name: "",
            description: "",
          };

    const placeholderDatasources =
      conversationDatasources
        .filter((x) => x.dataSources.length)
        .map((x) => x.dataSources)
        .flat() || [];

    useEffect(() => {
      if (shouldGenerateWorkflowPlan && referenceConvo != undefined) {
        setGeneratingContent(true);

        (async () => {
          const lastAnalyst = referenceConvo?.messageSet?.findLast(
            (m) => m.analyst,
          )?.analyst;

          const analystId = analysts.find((a) => a.name === lastAnalyst)!.id;

          const { content: generatedContent } =
            await NewtonApi.generateIceBreaker(analystId, conversationId);
          setContent(generatedContent);
          setGeneratingContent(false);
        })();
      }
    }, [analysts, referenceConvo, conversationId, shouldGenerateWorkflowPlan]);

    // Form state
    const [name, setName] = useState<string>(
      existingIceBreaker?.name || placeholderName!,
    );
    const [description, setDescription] = useState<string>(
      existingIceBreaker?.description || placeholderDescription!,
    );
    const [content, setContent] = useState<string>(
      existingIceBreaker?.content || "",
    );

    const [dataSources, setDataSources] = useState<DataSource[]>(
      existingIceBreaker?.dataSources || placeholderDatasources,
    );

    const [placeholderMemories, setPlaceholderMemories] = useState<
      { label: string; value: number }[]
    >([]);
    const [selectedMemories, setSelectedMemories] = useState<Memory[]>(
      existingIceBreaker?.memories || [],
    );

    const createOrSave = useCallback(async () => {
      if (!templateId) {
        const iceBreaker = await createIceBreaker({
          name,
          description,
          content,
          dataSources: dataSources?.map((ds) => ds.id),
          memories: selectedMemories.map((m) => m.id),
        });
        navigate(`/templates/edit/${iceBreaker.id}`);
      } else {
        updateIceBreaker({
          id: templateId as unknown as number,
          name,
          description,
          content,
          owner: existingIceBreaker?.owner,
          dataSources: dataSources?.map((ds) => ds.id),
          memories: selectedMemories.map((m) => m.id),
        });
      }
    }, [
      templateId,
      name,
      description,
      content,
      dataSources,
      selectedMemories,
      createIceBreaker,
      updateIceBreaker,
      navigate,
    ]);

    useImperativeHandle(
      ref,
      () => ({
        save: () => {
          createOrSave();
        },
      }),
      [createOrSave],
    );

    const handleSelectDataSource = (val: DataSource) => {
      const selected = allDatasources.find((ds) => ds.id === val.value);
      const isRepeated = dataSources.find((ds) => ds.id === val.value);

      if (selected && !isRepeated) {
        setDataSources((dsList) => [...dsList, selected]);
      }
    };

    const handleRemoveDataSource = (id: number) => {
      setDataSources((prev) => prev.filter((ds) => ds.id !== id));
    };

    return (
      <Space direction="vertical" style={{ gap: "20px", width: "100%" }}>
        <TemplateFormCard title="General">
          <Form layout="vertical">
            <Form.Item label="Title">
              <Input
                autoFocus
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Item>
            <Form.Item label="Description">
              <Input.TextArea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Form.Item>
          </Form>
        </TemplateFormCard>
        <TemplateFormCard title="Workflow Plan">
          <Form layout="vertical">
            {useMarkdownEditor ? (
              <Form.Item
                className={styles.promptLabel}
                label={
                  <>
                    <div>Plan Prompt</div>
                    <div>
                      <Button
                        className={styles.markdownToggle}
                        type="link"
                        onClick={() => setMarkdownEditor(false)}
                      >
                        Edit Raw
                      </Button>
                    </div>
                  </>
                }
              >
                {generatingContent ? (
                  <Skeleton active className={styles.skeleton} />
                ) : (
                  <MarkdownEditor
                    initialContent={content}
                    onChange={setContent}
                  />
                )}
              </Form.Item>
            ) : (
              <Form.Item
                className={styles.promptLabel}
                label={
                  <>
                    <div>Plan Prompt</div>
                    <div>
                      <Button
                        className={styles.markdownToggle}
                        type="link"
                        onClick={() => setMarkdownEditor(true)}
                      >
                        Edit Markdown
                      </Button>
                    </div>
                  </>
                }
              >
                <Input.TextArea
                  autoSize={{ minRows: 10, maxRows: 14 }}
                  placeholder="Enter task"
                  value={content}
                  onChange={(e) => setContent(e.target.value)}
                  disabled={generatingContent}
                />
              </Form.Item>
            )}
          </Form>
        </TemplateFormCard>
        <TemplateFormCard title="Data sources">
          <Form layout="vertical">
            <Form.Item label="Data source">
              <Select
                onSelect={(_e, val) => handleSelectDataSource(val)}
                options={allDatasources.map((ds) => ({
                  ...ds,
                  value: ds.id,
                  label: ds.name,
                }))}
                placeholder="Select data source"
                value={undefined}
              />
            </Form.Item>
            <Space className={styles.dataSourceList} direction="vertical">
              {dataSources.map((ds) => (
                <Card
                  className={styles.dataSource}
                  classNames={{ body: styles.body }}
                  key={ds.id}
                >
                  <div className={styles.name}>{ds.name}</div>
                  <Button
                    type="text"
                    className={styles.deleteButton}
                    icon={<DeleteIcon className={styles.deleteIcon} />}
                    onClick={() => handleRemoveDataSource(ds.id)}
                  />
                </Card>
              ))}
            </Space>
          </Form>
        </TemplateFormCard>
        <TemplateFormCard title="Memories">
          <Form layout="vertical">
            <Form.Item label="Memories">
              <Loader
                fillVertical
                spinning={memoriesLoading}
                style={{ width: "100%" }}
              >
                <Space.Compact style={{ width: "100%" }}>
                  {/* This is an odd behavior they are asking to build for the select, if we see this elsewhere its worth creating a component */}
                  <Select
                    disabled={shouldGenerateWorkflowPlan}
                    mode="multiple"
                    placeholder="Select memories"
                    options={memories
                      .filter(
                        (m) => !selectedMemories.find((sm) => sm.id === m.id),
                      )
                      .map((item: Memory) => ({
                        label: item.name,
                        value: item.id,
                      }))}
                    onBlur={() => {
                      setSelectedMemories(
                        [
                          ...selectedMemories,
                          ...placeholderMemories.map(
                            (m) =>
                              memories.find(
                                (mem) => mem.id === m.value,
                              ) as Memory,
                          ),
                        ].filter((m, i, arr) => arr.indexOf(m) === i),
                      );
                      setPlaceholderMemories([]);
                    }}
                    onSelect={(_e, option) => {
                      setPlaceholderMemories((memories) => [
                        ...memories,
                        option,
                      ]);
                    }}
                    onDeselect={(value) => {
                      setPlaceholderMemories((memories) =>
                        memories.filter((m) => m.value !== value),
                      );
                    }}
                    optionFilterProp="label"
                    filterOption={(input, option) =>
                      option!.label.toLowerCase().includes(input.toLowerCase())
                    }
                    value={placeholderMemories?.map((u) => u.value)}
                  />
                  <Button
                    disabled={shouldGenerateWorkflowPlan}
                    style={{ height: "40px" }}
                    onClick={() => setModalOpen(true)}
                  >
                    Create
                  </Button>
                  <MemoryModal
                    isOpen={isModalOpen}
                    onClose={() => {
                      setModalOpen(false);
                      refreshMemories();
                    }}
                  />
                </Space.Compact>
                <Flex className={styles.memoryNames}>
                  {selectedMemories.map((mem) => (
                    <Tag
                      key={mem.id}
                      className={styles.memoryTag}
                      closeIcon
                      onClose={() => {
                        setSelectedMemories(
                          selectedMemories.filter((m) => m.id !== mem.id),
                        );
                      }}
                    >
                      {mem.name}
                    </Tag>
                  ))}
                </Flex>
              </Loader>
            </Form.Item>
          </Form>
        </TemplateFormCard>
      </Space>
    );
  },
);
