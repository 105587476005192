import { LockOutlined } from "@ant-design/icons";
import { useAuth0 } from "@auth0/auth0-react";
import { Button, Form, Layout, Typography, theme } from "antd";
import Logo from "../../assets/logo.svg?react";

const { useToken } = theme;
const { Text, Title } = Typography;

const Login = () => {
  const { loginWithRedirect } = useAuth0();
  const { token } = useToken();

  const styles = {
    container: {
      margin: "0 auto",
      padding: `${token.paddingXL}px`,
      width: "380px",
    },
    layout: {
      background: "white",
      justifyContent: "center",
    },
    footer: {
      marginTop: token.marginLG,
      textAlign: "center",
      width: "100%",
    },
    forgotPassword: {
      float: "right",
    },
    header: {
      marginBottom: token.marginXL,
      textAlign: "center" as const,
    },
    logo: {
      alignItems: "center",
      display: "flex",
      justifyContent: "center",
      marginBottom: token.marginXL,
    },
    section: {
      alignItems: "center",
      backgroundColor: token.colorBgContainer,
      display: "flex",
      height: "auto",
      padding: `${token.sizeXXL}px 0px`,
    },
    text: {
      color: token.colorTextSecondary,
    },
    title: {
      fontSize: token.fontSizeHeading2,
    },
  };

  return (
    <Layout style={styles.layout}>
      <section style={styles.section}>
        <div style={styles.container}>
          <div style={styles.logo}>
            <Logo width={130} />
          </div>
          <div style={styles.header}>
            <Title style={styles.title}>Sign in</Title>
            <Text style={styles.text}>
              Welcome back to Newton! Please login below to continue.
            </Text>
          </div>
          <Form
            name="normal_login"
            initialValues={{
              remember: true,
            }}
            onFinish={loginWithRedirect}
            layout="vertical"
            requiredMark="optional"
          >
            <Form.Item style={{ marginBottom: "0px" }}>
              <Button
                block={true}
                type="primary"
                onClick={() => loginWithRedirect()}
                icon={<LockOutlined />}
              >
                Login
              </Button>
            </Form.Item>
          </Form>
        </div>
      </section>
    </Layout>
  );
};

export default Login;
