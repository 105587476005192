import React from "react";
import { ConversationView } from "../conversation-view/ConversationView";
import { ConversationContextProvider } from "contexts/ConversationContext";

export const ConversationsView: React.FC = () => {
  return (
    <ConversationContextProvider>
      <ConversationView />
    </ConversationContextProvider>
  );
};
