import { Button, Col, Flex, Row, Typography } from "antd";
import { NewConversationButton } from "components/new-converation-btn/NewConversationButton";
import { PageSection } from "components/page-section/PageSection";
import {
  SkeletonTemplateCard,
  TemplateCard,
  ViewAllTemplates,
} from "components/template-card/TemplateCard";
import { useDataProviderContext } from "contexts/DataProviderContext";
import styles from "./Home.module.scss";
import { Table } from "components/table/Table";
import { formatDate } from "utils/formatDate";
import { useNavigate } from "react-router-dom";

export const Home: React.FC = () => {
  const { conversations, me, iceBreakers, isLoading } =
    useDataProviderContext();

  const name = me?.givenName || me?.fullName.split(" ")[0];

  const navigate = useNavigate();

  return (
    <Flex justify="center" className={styles.home} vertical>
      <div style={{ height: "84px" }}>
        {me && (
          <>
            <Typography.Title
              aria-hidden
              level={1}
              style={{
                color: "var(--nri-color-primary)",
                height: "34px",
                fontSize: "28px",
                fontWeight: "700",
                letterSpacing: "0.01em",
              }}
            >
              Welcome{name ? `, ${name}` : ""} 👋🏻
            </Typography.Title>
            <Typography.Title
              level={4}
              style={{
                fontSize: "16px",
                fontWeight: "400",
              }}
            >
              Start your workday with Newton Research
            </Typography.Title>
          </>
        )}
      </div>
      <div
        style={{
          background: "white",
          padding: 32,
          borderRadius: 20,
          minHeight: 200,
          width: "100%",
        }}
      >
        <Flex vertical gap="60px">
          <PageSection
            button={<NewConversationButton />}
            heading="Analytics workflow templates"
            subheading="Put Newton to work using built-in or custom templates"
          >
            <Row gutter={{ md: 16 }}>
              {iceBreakers.length > 0
                ? iceBreakers.slice(0, 3).map((iceBreaker) => (
                    <Col
                      key={iceBreaker.id}
                      span={6}
                      style={{ cursor: "pointer" }}
                    >
                      <TemplateCard iceBreaker={iceBreaker} />
                    </Col>
                  ))
                : Array.from({ length: 4 }).map((_, idx) => (
                    <Col key={idx} span={6} style={{ cursor: "pointer" }}>
                      <SkeletonTemplateCard />
                    </Col>
                  ))}
              <Col span={6}>
                <ViewAllTemplates />
              </Col>
            </Row>
          </PageSection>
          <PageSection
            button={
              <Button size="large" onClick={() => navigate("/c")}>
                Go to conversations
              </Button>
            }
            heading="Latest conversations"
            subheading="Explore your recent conversations"
          >
            <Table
              className={styles.latestTable}
              loading={isLoading}
              onRow={(record) => {
                return {
                  onClick: () => {
                    navigate(`/c/${record.id}`);
                  },
                };
              }}
              columns={[
                {
                  title: "Name",
                  dataIndex: "name",
                  key: "name",
                  render: (text, data) => (
                    <div>
                      <span style={{ color: "#453F68", fontWeight: 500 }}>
                        {text}
                      </span>
                      <br />
                      <Typography.Text
                        ellipsis
                        type="secondary"
                        style={{ overflow: "hidden", width: "200px" }}
                      >
                        {data.description}
                      </Typography.Text>
                    </div>
                  ),
                },

                {
                  title: "Date",
                  dataIndex: "createdAt",
                  key: "createdAt",
                  render: (createdAt) => formatDate(createdAt),
                },
                {
                  title: "",
                  dataIndex: "id",
                  key: "createdAt",
                  render: (id) => (
                    <a
                      href={`/c/${id}`}
                      style={{ color: "#695AD9", fontWeight: 500 }}
                    >
                      Open
                    </a>
                  ),
                },
              ]}
              dataSource={conversations.slice(0, 3)}
            />
          </PageSection>
        </Flex>
      </div>
    </Flex>
  );
};
