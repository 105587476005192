type FetchAllFeedbacksRequestAction = {
  type: "FETCH_ALL_FEEDBACKS_REQUEST";
};

type FetchAllFeedbacksAction = {
  type: "FETCH_ALL_FEEDBACKS";
  payload: Pagination<FeedbackData>;
};

type CleanFeedbacksAction = {
  type: "CLEAN_ALL_FEEDBACKS";
};

type FetchConversationFeedbacksRequestAction = {
  type: "FETCH_CONVERSATION_FEEDBACKS_REQUEST";
};

type FetchConversationFeedbacksAction = {
  type: "FETCH_CONVERSATION_FEEDBACKS";
  payload: Conversation;
};

type CleanConversationFeedbacksAction = {
  type: "CLEAN_CONVERSATION_FEEDBACKS";
};
type UpdateConversationFeedbacksMessageAction = {
  type: "UPDATE_CONVERSATION_FEEDBACKS_MESSAGE";
  payload: { message: Message; conversationId: ConversationId };
};

type FeedbackActions =
  | FetchAllFeedbacksRequestAction
  | FetchAllFeedbacksAction
  | CleanFeedbacksAction
  | FetchConversationFeedbacksRequestAction
  | FetchConversationFeedbacksAction
  | CleanConversationFeedbacksAction
  | UpdateConversationFeedbacksMessageAction;

export const feedbackReducer = (
  state: {
    feedbacks: {
      loading: boolean | null;
      next: string | null;
      previous: string | null;
      results: FeedbackData[];
      count: number | null;
    };
    conversationFeedbacks: {
      data: Conversation | undefined;
      loading: boolean | null;
    };
  },
  action: FeedbackActions,
) => {
  switch (action.type) {
    case "FETCH_ALL_FEEDBACKS_REQUEST":
      return {
        ...state,
        feedbacks: {
          ...state.feedbacks,
          loading: true,
        },
      };
    case "FETCH_ALL_FEEDBACKS":
      return {
        ...state,
        feedbacks: {
          ...state.feedbacks,
          count: action.payload.count,
          loading: false,
          results: action.payload.results,
        },
      };
    case "CLEAN_ALL_FEEDBACKS":
      return {
        ...state,
        feedbacks: {
          ...state.feedbacks,
          count: null,
          loading: null,
          results: [],
        },
      };

    case "FETCH_CONVERSATION_FEEDBACKS_REQUEST":
      return {
        ...state,
        conversationFeedbacks: {
          ...state.conversationFeedbacks,
          loading: true,
        },
      };
    case "FETCH_CONVERSATION_FEEDBACKS":
      return {
        ...state,
        conversationFeedbacks: {
          loading: false,
          data: action.payload,
        },
      };
    case "CLEAN_CONVERSATION_FEEDBACKS":
      return {
        ...state,
        conversationFeedbacks: {
          data: undefined,
          loading: null,
        },
      };
    case "UPDATE_CONVERSATION_FEEDBACKS_MESSAGE":
      return {
        ...state,
        conversationFeedbacks: {
          ...state.conversationFeedbacks,
          data: {
            ...(state.conversationFeedbacks.data as Conversation),
            messageSet: state.conversationFeedbacks.data?.messageSet.map(
              (m: Message) =>
                m.id === action.payload.message.id ? action.payload.message : m,
            ) as Message[],
          },
        },
      };
    default:
      return state;
  }
};
