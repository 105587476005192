import { Tabs, Typography } from "antd";
import styles from "./MemoryAdministration.module.scss";
import { FC } from "react";
import { ConversationFeedbacks } from "./ConversationFeedbacks";
import { useDataProviderContext } from "contexts/DataProviderContext";
export const MemoryAdministration: FC = () => {
  const { me } = useDataProviderContext();
  const memoryBoardItems: {
    hide: boolean | undefined;
    label: string;
    key: string;
    children: JSX.Element;
  }[] = [
    {
      hide: false,
      label: "Conversation feedback",
      key: "1",
      children: <ConversationFeedbacks />,
    },
    {
      hide: !me?.isSuperuser,
      label: "Memory admin",
      key: "2",
      children: <div> Coming soon </div>,
    },
  ].filter(({ hide }) => !hide);

  return (
    <div className={styles.memoryBoard}>
      <div className={styles.memoryBoardHeader}>
        <Typography.Text className={styles.memoryBoardTitle}>
          Feedback and memory administration
        </Typography.Text>
      </div>
      <div className={styles.memoryBoardContent}>
        <Tabs
          className={styles.memoryBoardTabs}
          defaultActiveKey="1"
          items={memoryBoardItems}
        />
      </div>
    </div>
  );
};
