import { message } from "antd";

export const validateDatasourceFile = (file: File) => {
  if (file.type !== "text/csv") {
    message.error(
      "Error: Unsupported file type. Please upload a valid file format.",
    );
    return false;
  }
  return true;
};
