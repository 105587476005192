import { Button, Flex, Input, Layout, message } from "antd";
import classnames from "classnames";
import { SelectAnalystDropdown } from "components/conversations-view/SelectAnalystDropdown";
import { DataSourceTags } from "components/datasource-tags/DataSourceTags";
import { useConversationContext } from "contexts/ConversationContext";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import SendIcon from "../../assets/icons/plane.svg?react";
import StopIcon from "../../assets/icons/stop.svg?react";
import { useDataProviderContext } from "../../contexts/DataProviderContext";
import { cx } from "../../utils/cx";
import styles from "./ConversationView.module.scss";

export const Prompt: React.FC = () => {
  const {
    activeConversation,
    isConvoStreaming,
    isLoading,
    isStreaming,
    prompt,
  } = useDataProviderContext();

  const {
    activeMessage,
    createMessage,
    restoreMessage,
    selectedDataSources,
    stopAnalyst,
    toggleSelectedDataSource,
    updateMessage,
  } = useConversationContext();

  const [focus, setFocus] = useState(false);
  const [isStopping, setStopping] = useState(false);

  const previouslySelectedDataSources = useMemo(() => {
    if (!activeConversation) return selectedDataSources;
    const uniqueDatasourcesMap = new Map(
      selectedDataSources.map((item) => [item.id, item]),
    );
    return Array.from(uniqueDatasourcesMap.values());
  }, [activeConversation, selectedDataSources]);

  const conversationId = activeConversation?.id;

  useEffect(() => {
    if (isLoading) return;
    // Bug prompt is being set as an empty object on load
    if (Object.keys(prompt).length) {
      if (!prompt.conversationId) {
        updateMessage(prompt);
      } else {
        restoreMessage(prompt);
      }
    }
  }, [isLoading, prompt]);

  const handleSubmit = useCallback(async () => {
    if (activeConversation?.ownerStatus === "shared") {
      updateMessage({
        text: "",
      });
      message.error("Insufficient permission. Only owners can send messages.");
      return;
    }

    if (isStreaming) {
      setStopping(true);
      await stopAnalyst();
    } else if (activeMessage && !isConvoStreaming(conversationId!)) {
      await createMessage(activeMessage);
    }
  }, [activeMessage, conversationId, createMessage, activeConversation]);

  // HACK: FIXME: Ideally the API would let us know when the "stopping" process is done
  useEffect(() => {
    // For now, we can assume that while isStopping is true (user clicked stop), and
    // that we are still streaming, the analyst is still stopping. When isStreaming flips, we
    // are assuming the analyst has stopped.
    if (!isStreaming && isStopping) setStopping(false);
  }, [isStopping, isStreaming]);

  const Icon = isStreaming ? StopIcon : SendIcon;

  return (
    <Layout.Footer
      className={classnames(styles.footer, { [styles.active]: activeMessage })}
    >
      <Flex style={{ marginLeft: "20px" }}>
        <SelectAnalystDropdown />
        <DataSourceTags
          locked={[]}
          selected={previouslySelectedDataSources}
          toggle={toggleSelectedDataSource}
        />
      </Flex>
      <Flex
        align="end"
        className={cx(styles.prompt, { [styles.focus]: focus })}
        flex={1}
      >
        <Input.TextArea
          autoSize={{ minRows: 1 }}
          disabled={isLoading || isStopping}
          onBlur={() => setFocus(false)}
          onChange={(e) =>
            updateMessage({
              text: e.target.value,
            })
          }
          onFocus={() => setFocus(true)}
          onKeyDown={(e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              e.preventDefault();
              handleSubmit();
            }
          }}
          placeholder="Type a message..."
          value={activeMessage?.text}
          variant="borderless"
        />
        <div className={styles.button}>
          <Button
            disabled={isLoading || isStopping}
            icon={<Icon height={24} />}
            onClick={handleSubmit}
            shape="circle"
            style={{ boxShadow: "none", height: 44, width: 44 }}
            type="primary"
          />
        </div>
      </Flex>
    </Layout.Footer>
  );
};
