import React, { useState, useEffect } from "react";

type Props = {
  date: string;
};

export const TimeAgo: React.FC<Props> = ({ date }) => {
  const [timeAgo, setTimeAgo] = useState("");

  useEffect(() => {
    const updateFrequency = 60000;
    const targetDate = new Date(date).getTime();
    const calculateTimeAgo = () => {
      const now = new Date().getTime();
      const secondsElapsed = Math.round((now - targetDate) / 1000);
      const minutesElapsed = Math.round(secondsElapsed / 60);
      const hoursElapsed = Math.round(minutesElapsed / 60);
      const daysElapsed = Math.round(hoursElapsed / 24);

      if (secondsElapsed < 60) {
        setTimeAgo("now");
      } else if (minutesElapsed < 60) {
        setTimeAgo(`${minutesElapsed}m`);
      } else if (hoursElapsed < 24) {
        setTimeAgo(`${hoursElapsed}h`);
      } else {
        setTimeAgo(`${daysElapsed}d`);
      }
    };

    calculateTimeAgo();

    const interval = setInterval(calculateTimeAgo, updateFrequency);
    return () => clearInterval(interval);
  }, [date]);

  return <span>{timeAgo}</span>;
};
