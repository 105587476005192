import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Empty, Flex, Layout, Tabs } from "antd";
import { ConversationSidePanel } from "components/conversation-view/ConversationSidePanel";
import { Message } from "components/message/Message";
import { SlideInText } from "components/animations/SlideInText";
import { Loader } from "components/loader/Loader";
import { useFeedbackContext } from "contexts/FeedbackContext";
import { motion } from "framer-motion";
import { useAutoScroll } from "hooks/useAutoScroll";
import { FC, useEffect, useMemo, useRef } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import styles from "./FeedbacksView.module.scss";
import {
  ConversationFeedbacksViewEnum,
  FeedbackScopeEnum,
} from "../../types/enum";
import { findKeysInLocalStorage } from "utils/findKeysInLocalStorage";

export const FeedbacksView: FC = () => {
  const {
    view,
    setView,
    fetchConversationFeedbacks,
    cleanConversationFeedbacks,
    conversationFeedbacks,
  } = useFeedbackContext();
  const { conversationId } = useParams();
  const [searchParams] = useSearchParams();
  const messageId = searchParams.get("message_id");

  const navigate = useNavigate();
  const ConversationWrapper = motion(Flex);

  useEffect(() => {
    if (conversationId) {
      fetchConversationFeedbacks(
        conversationId as ConversationId,
        FeedbackScopeEnum.ALL,
        messageId as MessageId,
      );
    }

    return () => {
      findKeysInLocalStorage("feedback").forEach((key) => {
        localStorage.removeItem(key);
      });
      findKeysInLocalStorage("message-set").forEach((key) => {
        localStorage.removeItem(key);
      });
      cleanConversationFeedbacks();
    };
  }, [conversationId]);

  const selectedConversation = useMemo(() => {
    return conversationFeedbacks;
  }, [conversationFeedbacks]);

  const conversationMessages = useMemo(() => {
    if (!selectedConversation) return [];
    return view === ConversationFeedbacksViewEnum.MESSAGES
      ? selectedConversation.messageSet
      : selectedConversation.messageSet.filter(
          (msg) => msg.feedbackSet?.length,
        );
  }, [selectedConversation, view]);
  const divRef = useRef<HTMLDivElement>(null);
  const { jumpToBottom } = useAutoScroll(divRef, [conversationMessages]);

  // Jump to bottom when changing tabs
  useEffect(() => {
    if (view) {
      jumpToBottom();
    }
  }, [jumpToBottom, view]);

  return (
    <>
      {!selectedConversation ? (
        <Loader className={styles.loader} />
      ) : (
        <Layout.Content className={styles.conversation}>
          <Layout.Header className={styles.header}>
            <div className={styles.headerContent}>
              <div className={styles.title_wrapper}>
                <ArrowLeftOutlined
                  onClick={() => {
                    navigate("/memories");
                  }}
                />
                <SlideInText
                  level={3}
                  className={styles.title}
                  text={selectedConversation?.name || ""}
                />
              </div>
              <Tabs
                activeKey={view}
                className={styles.tabs}
                items={[
                  {
                    key: ConversationFeedbacksViewEnum.MESSAGES,
                    label: "Conversation view",
                  },
                  {
                    key: ConversationFeedbacksViewEnum.FEEDBACKS,
                    label: "Feedback view",
                  },
                ]}
                onChange={(view) =>
                  setView(view as ConversationFeedbacksViewEnum)
                }
              />
            </div>
            <Button type="primary">Create memory</Button>
          </Layout.Header>
          <div className={styles.content}>
            <div className={styles.conversationCard}>
              <div className={styles.messages}>
                <div className={styles.messagesContainer} ref={divRef}>
                  <Flex className={styles.text} vertical>
                    <ConversationWrapper
                      animate={{ opacity: 1 }}
                      gap="32px"
                      transition={{ duration: 1 }}
                      vertical
                    >
                      {conversationMessages.length > 0 ? (
                        conversationMessages?.map((msg: Message, idx) => (
                          <Message
                            index={idx}
                            conversation={selectedConversation!}
                            message={msg}
                            key={`${msg.id}-${idx}`}
                          />
                        ))
                      ) : (
                        <Empty
                          description="No messages"
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                        />
                      )}
                    </ConversationWrapper>
                  </Flex>
                </div>
              </div>
              <div className={styles.sidePanel}>
                <ConversationSidePanel />
              </div>
            </div>
          </div>
        </Layout.Content>
      )}
    </>
  );
};
