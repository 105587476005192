import { DatabaseOutlined } from "@ant-design/icons";
import { Col, Empty, Flex, Row, Tabs } from "antd";
import { DatasourceCard } from "components/datasource-card/DatasourceCard";
import { useDataProviderContext } from "contexts/DataProviderContext";
import { useEffect, useMemo, useState } from "react";
import { RetrieveTypesEnum } from "../../types/enum";
import { Loader } from "components/loader/Loader";
import styles from "../blank-slate/BlankSlate.module.scss";

type DataSourceListProps = {
  toggle: (datasource: DataSource) => void;
  selected: DataSource[];
  locked?: DataSource[];
};

type DataSourceTabProps = {
  dataSources: DataSource[];
} & DataSourceListProps;

const enhanceDataSource = (
  source: DataSource,
): DataSource & {
  color: string;
  icon: React.ComponentType;
} => ({
  ...source,
  icon: DatabaseOutlined,
  color: [
    "var(--nri-color-primary-vibrant)",
    "var(--nri-color-primary)",
    "var(--nri-color-secondary)",
  ][source.id! % 3],
});
const DataSourceTab = ({
  dataSources,
  locked,
  selected,
  toggle,
}: DataSourceTabProps) => {
  return (
    <div>
      <Row gutter={[16, { md: 16 }]}>
        {dataSources?.length == 0 ? (
          <Flex
            align="center"
            justify="center"
            style={{ minHeight: "300px", width: "100%" }}
          >
            <Empty
              description="No data sources"
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              style={{ margin: 0, padding: 0 }}
            />
          </Flex>
        ) : (
          dataSources?.map((ds) => (
            <Col key={ds.id} span={8}>
              <DatasourceCard
                datasource={ds}
                disabled={!!locked?.find((i) => i.id === ds.id)}
                isSelected={!!selected.find((i) => i.id === ds.id)}
                onToggle={() => {
                  toggle(ds);
                }}
              />
            </Col>
          ))
        )}
      </Row>
    </div>
  );
};

export const DataSourceList = ({
  locked,
  selected,
  toggle,
}: DataSourceListProps) => {
  const [filterQuery, setFilterQuery] = useState<RetrieveType>(
    RetrieveTypesEnum.ALL,
  );

  const {
    filteredDatasources: datasources,
    filterDatasources,
    cleanFilterDatasources,
  } = useDataProviderContext();

  const enhancedSources = useMemo(
    () => datasources?.results?.map(enhanceDataSource) || [],
    [datasources],
  );

  useEffect(() => {
    if (filterQuery) filterDatasources(filterQuery);

    return () => cleanFilterDatasources();
  }, [filterQuery]);

  const ItemContent = () => (
    <>
      {datasources.loading === true || datasources.loading === null ? (
        <div className={styles.emptyContainer}>
          <Loader />
        </div>
      ) : (
        <DataSourceTab
          locked={locked}
          dataSources={enhancedSources}
          selected={selected}
          toggle={toggle}
        />
      )}
    </>
  );

  const handleTabChange = (key: string) => {
    setFilterQuery(key as RetrieveType);
  };

  return (
    <Tabs
      defaultActiveKey="all"
      onChange={handleTabChange}
      items={[
        {
          key: "all",
          label: "All",
          children: <ItemContent />,
        },
        {
          key: "organization",
          label: "Organization",
          children: <ItemContent />,
        },
        {
          key: "owned",
          label: "My data sources",
          children: <ItemContent />,
        },
        {
          key: "shared",
          label: "Shared with me",
          children: <ItemContent />,
        },
      ]}
    ></Tabs>
  );
};
