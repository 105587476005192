import { DownOutlined } from "@ant-design/icons";
import { Flex, MenuProps } from "antd";
import Dropdown from "antd/es/dropdown/dropdown";
import { useConversationContext } from "contexts/ConversationContext";
import { useDataProviderContext } from "contexts/DataProviderContext";
import { useMemo } from "react";
import styles from "./SelectAnalystsDropdown.module.scss";

export const SelectAnalystDropdown: React.FC = () => {
  const { analysts, activeAnalyst, defaultAnalyst, isLoading } =
    useDataProviderContext();
  const {
    selectedAnalyst,
    toggleSelectedAnalyst: toggle,
    isAnalystSelected: inList,
  } = useConversationContext();

  // Custom analyst first, then prior analyst, then default analyst
  const analyst = useMemo(
    () => selectedAnalyst || activeAnalyst || defaultAnalyst,
    [selectedAnalyst, activeAnalyst, defaultAnalyst],
  );

  const items = useMemo<MenuProps["items"]>(
    () => [
      {
        type: "group",
        label: "Models:",
        className: styles.header,
      },
      ...analysts.map((analyst) => ({
        label: analyst.name,
        key: `${analyst.id}`,
        className: styles.menuItem,
        onClick: () => {
          !inList(analyst) && toggle(analyst);
        },
      })),
    ],
    [analysts, toggle],
  );

  if (isLoading) return null;

  return (
    <>
      <Dropdown
        className={styles.dropdown}
        menu={{
          items,
          selectable: true,
          defaultSelectedKeys: [`${analyst.id}`],
        }}
        trigger={["click"]}
      >
        <a onClick={(e) => e.preventDefault()}>
          <Flex className={styles.selected}>
            {analyst?.name}
            <DownOutlined className={styles.chevron} />
          </Flex>
        </a>
      </Dropdown>
    </>
  );
};
