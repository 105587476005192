import { Col, Row } from "antd";
import { BaseLayout } from "components/layouts/BaseLayout";
import { PageSection } from "components/page-section/PageSection";
import {
  SkeletonTemplateCard,
  TemplateCard,
} from "components/template-card/TemplateCard";
import { useDataProviderContext } from "contexts/DataProviderContext";
import { useNavigate } from "react-router-dom";
import styles from "./Layout.module.scss";

export const TemplatesPage = () => {
  const navigate = useNavigate();
  const { iceBreakers } = useDataProviderContext();

  return (
    <BaseLayout
      buttonProps={{
        children: "Create Template",
        onClick: () => navigate("/templates/new"),
      }}
      onBack={() => navigate("/")}
      subtitle="Start conversation with system or created by you templates"
      title="Conversation Templates"
    >
      <div className={styles.content}>
        <PageSection>
          <Row gutter={[16, 16]}>
            {iceBreakers.length > 0
              ? iceBreakers.map((iceBreaker) => (
                  <Col
                    key={iceBreaker.id}
                    span={6}
                    style={{ cursor: "pointer" }}
                  >
                    <TemplateCard iceBreaker={iceBreaker} />
                  </Col>
                ))
              : Array.from({ length: 3 }).map((_, idx) => (
                  <Col key={idx} span={6} style={{ cursor: "pointer" }}>
                    <SkeletonTemplateCard />
                  </Col>
                ))}
          </Row>
        </PageSection>
      </div>
    </BaseLayout>
  );
};
