export enum Roles {
  USER = "user",
  USER_PROXY = "user_proxy",
  ASSISTANT = "assistant",
  SUMMARY = "summary",
}

export enum ConversationViewEnum {
  ALL = "ALL",
  EXECUTIVE = "EXECUTIVE",
}

export enum RetrieveTypesEnum {
  ALL = "all",
  ORGANIZATION = "organization",
  OWNED = "owned",
}

export enum FeedbackTypeEnum {
  LIKE = "Positive",
  DISLIKE = "Negative",
  QUESTION = "Question",
}

export enum ConversationFeedbacksViewEnum {
  MESSAGES = "MESSAGES",
  FEEDBACKS = "FEEDBACKS",
}

export enum FeedbackScopeEnum {
  ALL = "all",
  USER = "user",
}
