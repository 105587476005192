import { Flex, Layout, Skeleton } from "antd";

import { SlideInText } from "components/animations/SlideInText";
import styles from "./Layouts.module.scss";
import { PropsWithChildren } from "react";
import classnames from "classnames";

type Props = PropsWithChildren & {
  headerExtra?: React.ReactNode;
  isLoading?: boolean;
  title: string;
};

export const EditorLayout: React.FC<Props> = ({
  children,
  headerExtra,
  isLoading,
  title,
}) => {
  return (
    <Layout className={classnames(styles.editorLayout, styles.page)}>
      <Layout.Header className={styles.header}>
        <div className={styles.headerContent}>
          <div className={styles.title_wrapper}>
            {isLoading ? (
              <Skeleton.Button active className={styles.skeleton} />
            ) : (
              <SlideInText className={styles.title} text={title} level={3} />
            )}
          </div>
        </div>
        <Flex align="center" justify="end">
          {isLoading ? (
            <Skeleton.Button active className={styles.skeleton} />
          ) : (
            headerExtra
          )}
        </Flex>
      </Layout.Header>
      <Layout.Content className={styles.content}>
        {isLoading ? <Skeleton active /> : children}
      </Layout.Content>
    </Layout>
  );
};
