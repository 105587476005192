import { Flex, Tag } from "antd";
import styles from "./DataSourceTags.module.scss";

type DataSourceTagsProps = {
  toggle: (datasource: DataSource) => void;
  selected: DataSource[];
  locked?: DataSource[];
};

export const DataSourceTags = ({
  locked,
  selected,
  toggle,
}: DataSourceTagsProps) => {
  return (
    <Flex align="center">
      {locked?.map((ds) => (
        <Tag className={styles.tag} color="yellow">
          {ds.name}
        </Tag>
      ))}

      {selected?.map((ds) => (
        <Tag
          className={styles.tag}
          closeIcon
          color="purple"
          onClose={() => toggle(ds)}
        >
          {ds.name}
        </Tag>
      ))}
    </Flex>
  );
};
