import { Button, Flex, Modal, Typography } from "antd";
import { FC, useCallback, useMemo } from "react";

import { DataSourceList } from "components/datasource-list/DataSourceList";
import { useConversationContext } from "contexts/ConversationContext";
import { useDataProviderContext } from "contexts/DataProviderContext";
import { useList } from "hooks/useList";

type Props = {
  visible: boolean;
  onClose(): void;
};

export const SelectExistingDatasourceModal: FC<Props> = ({
  visible,
  onClose,
}) => {
  const { activeConversation, conversationDatasources } =
    useDataProviderContext();
  const {
    selectedDataSources: previouslySelectedDataSources,
    toggleSelectedDataSource,
  } = useConversationContext();

  const lockedDataSources = useMemo(() => {
    if (!activeConversation) return [];

    return conversationDatasources.results
      .map((m) => m.dataSources)
      .flat()
      .filter(Boolean);
  }, [activeConversation]);

  const [selectedDataSources, { toggle }] = useList<DataSource>({
    initialSelection: previouslySelectedDataSources,
  });

  const memoizedToggle = useMemo(() => toggle, [toggle]);

  const memoizedSelected = useMemo(
    () => [...selectedDataSources, ...lockedDataSources],
    [selectedDataSources, lockedDataSources],
  );

  const handleAddSelected = useCallback(async () => {
    // Toggle items that are newly selected
    selectedDataSources
      .filter(
        (ds: DataSource) =>
          // only add data sources that were not previously selected
          previouslySelectedDataSources.find(
            (pds: DataSource) => pds.id === ds.id,
          ) === undefined,
      )
      .forEach(toggleSelectedDataSource);

    // Toggle items that used to be selected but are no longer selected
    previouslySelectedDataSources
      .filter(
        (ds: DataSource) =>
          // only add data sources that were not previously selected
          selectedDataSources.find((pds: DataSource) => pds.id === ds.id) ===
          undefined,
      )
      .forEach(toggleSelectedDataSource);

    onClose();
  }, [
    selectedDataSources,
    toggleSelectedDataSource,
    onClose,
    previouslySelectedDataSources,
  ]);

  return (
    <Modal
      title={
        <Flex justify="space-between" align="center">
          <Flex vertical>
            Select one or more Data Sources
            <Typography.Text type="secondary" style={{ fontWeight: 400 }}>
              Add one or more data sources
            </Typography.Text>
          </Flex>
          <Button onClick={handleAddSelected} type="primary">
            Add Selected
          </Button>
        </Flex>
      }
      closeIcon={false}
      open={visible}
      onCancel={onClose}
      footer={null}
      styles={{
        body: {
          padding: "0 20px ",
        },
        footer: {
          padding: "20px",
        },
        header: { padding: "20px", margin: 0 },
      }}
      width="80vw"
      style={{
        minWidth: "800px",
      }}
    >
      <DataSourceList
        selected={memoizedSelected}
        locked={lockedDataSources}
        toggle={memoizedToggle}
      />
    </Modal>
  );
};
