import { Collapse, Flex, Row } from "antd";
import { useDisplayObject } from "hooks/useDisplayObject";
import { Highlight, themes } from "prism-react-renderer";

import CaretDownIcon from "../../assets/icons/caret_down.svg?react";
import CaretUpIcon from "../../assets/icons/caret_up.svg?react";

import styles from "./Message.module.scss";
import { DisplayObject } from "./MessageDisplayObject";
import CopyIcon from "../../assets/icons/copy.svg?react";
import { copyToClipboard } from "utils/copyToClipboard";
import useLocalStorage from "hooks/useLocalStorage";
import { ConversationViewEnum } from "../../types/enum";

export const DisplayObjectSet: React.FC<{
  set: DisplayObject[];
  messageId: string;
  view: ConversationViewEnum;
}> = ({ set, messageId, view }) => {
  const [isCodeOpen, setIsCodeOpen] = useLocalStorage(
    `message-set-code-${messageId}`,
    false,
  );

  const [isLogOpen, setIsLogOpen] = useLocalStorage(
    `message-set-log-${messageId}`,
    false,
  );

  const codeContent = set.find((obj) => obj.type === "python");

  const logsContent = set.find((obj) => obj.type === "logs");

  const { data: code } = useDisplayObject(codeContent) as {
    data: unknown;
    displayObjects: DisplayObject[];
  };

  const { data: logs } = useDisplayObject(logsContent) as {
    data: unknown;
    displayObjects: DisplayObject[];
  };

  const showLogs =
    logsContent !== undefined && view === ConversationViewEnum.ALL;

  return (
    <Flex vertical gap={"20px"} justify="center" style={{ width: "100%" }}>
      {codeContent && (
        <Collapse
          className={styles.displayObjectCollapse}
          activeKey={isCodeOpen ? "code" : undefined}
          expandIconPosition="end"
          expandIcon={({ isActive }) =>
            isActive ? (
              <CaretUpIcon color="#453F68" />
            ) : (
              <CaretDownIcon color="#453F68" />
            )
          }
          items={[
            {
              children: (
                <Highlight
                  theme={themes.vsLight}
                  code={code as string}
                  language="python"
                >
                  {({ style, tokens, getLineProps, getTokenProps }) => (
                    <>
                      <button
                        className={styles.copyButton}
                        onClick={() =>
                          copyToClipboard(
                            code as string,
                            `Code copied`,
                            "Copy error",
                          )
                        }
                      >
                        <CopyIcon className={styles.copyIcon} />
                      </button>
                      <pre
                        className={`${styles.code} line-numbers`}
                        style={style}
                      >
                        {tokens.map((line, i) => (
                          <div key={i} {...getLineProps({ line, number: i })}>
                            <span
                              className={`line-number ${styles.lineNumber}`}
                            >
                              {i + 1}
                            </span>
                            {line.map((token, key) => (
                              <span {...getTokenProps({ token })} key={key} />
                            ))}
                          </div>
                        ))}
                      </pre>
                    </>
                  )}
                </Highlight>
              ),
              key: "code",
              label: "Code",
            },
          ]}
          onChange={() => {
            setIsCodeOpen((prev) => !prev);
          }}
        />
      )}
      {showLogs && (
        <Collapse
          className={styles.displayObjectCollapse}
          activeKey={isLogOpen ? "logs" : undefined}
          expandIconPosition="end"
          expandIcon={({ isActive }) =>
            isActive ? (
              <CaretUpIcon color="#453F68" />
            ) : (
              <CaretDownIcon color="#453F68" />
            )
          }
          items={[
            {
              children: (
                <Highlight
                  theme={themes.vsLight}
                  code={logs as string}
                  language="python"
                >
                  {({ style, tokens, getLineProps, getTokenProps }) => (
                    <>
                      <button
                        className={styles.copyButton}
                        onClick={() =>
                          copyToClipboard(
                            logs as string,
                            `Logs copied`,
                            "Copy error",
                          )
                        }
                      >
                        <CopyIcon className={styles.copyIcon} />
                      </button>
                      <pre
                        className={`${styles.code} line-numbers`}
                        style={style}
                      >
                        {tokens.map((line, i) => (
                          <div key={i} {...getLineProps({ line, number: i })}>
                            <span
                              className={`line-number ${styles.lineNumber}`}
                            >
                              {i + 1}
                            </span>
                            {line.map((token, key) => (
                              <span {...getTokenProps({ token })} key={key} />
                            ))}
                          </div>
                        ))}
                      </pre>
                    </>
                  )}
                </Highlight>
              ),
              key: "logs",
              label: "Logs",
            },
          ]}
          onChange={() => {
            setIsLogOpen((prev) => !prev);
          }}
        />
      )}
      {set
        .filter(
          (dobject) => dobject.type !== "logs" && dobject.type !== "python",
        )
        .map((dobject, index) => (
          <Row key={index} gutter={16} style={{ marginBottom: "20px" }}>
            <DisplayObject displayObject={dobject} />
          </Row>
        ))}
    </Flex>
  );
};
