import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Typography } from "antd";
import { TitleProps } from "antd/es/typography/Title";

const duration = 0.2;
const contentVariants = {
  initial: { opacity: 0, y: 8 },
  animate: { opacity: 1, y: 0, transition: { duration, delay: 0.05 } },
  exit: { opacity: 0, y: -5, transition: { duration } },
};

export const SlideInText: React.FC<{
  className?: string;
  level: TitleProps["level"];
  style?: React.CSSProperties;
  text: string;
}> = ({ className, level, text, style }) => {
  return (
    <div style={{ overflow: "hidden", padding: "10px 0" }}>
      <AnimatePresence mode="wait">
        {text && (
          <motion.div
            key={text}
            variants={contentVariants}
            initial="initial"
            animate="animate"
            exit="exit"
          >
            <Typography.Title className={className} level={level} style={style}>
              {text}
            </Typography.Title>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
