import { InboxIcon } from "@heroicons/react/24/outline";
import { Button, Flex, Layout, Menu, Skeleton } from "antd";
import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { useMemo, useState } from "react";
import { useParams } from "react-router";
import { cx } from "utils/cx";
import FunnelIcon from "../../assets/icons/filter.svg?react";
import { useDataProviderContext } from "../../contexts/DataProviderContext";
import styles from "./Sidebar.module.scss";
import { SidebarFilters } from "./SidebarFilters";
import { SidebarItem } from "./SidebarItem";
import { SearchInput } from "components/search-input/SearchInput";

type Props = { onChange?(t: string): void };

export const Sidebar: React.FC<Props> = () => {
  const { conversationId } = useParams();
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [filters, setFilters] = useState<SidebarFilters>({});
  const {
    additionalConversationsLoading,
    conversations: fullConversationList,
    loadMoreConversations,
    goToConversation,
    isLoading,
    me,
    showLoadMoreConversations,
    setCleanConversationDataSources,
    activeConversation,
  } = useDataProviderContext();
  const [filterText, setFilterText] = useState<string>();

  const conversations = useMemo(
    () =>
      fullConversationList?.filter((c) => {
        const conditions = [];
        switch (filters.time) {
          case "time-day":
            conditions.push(+new Date(c.updatedAt) > Date.now() - 86400000);
            break;
          case "time-week":
            conditions.push(+new Date(c.updatedAt) > Date.now() - 604800000);
            break;
          case "time-month":
            conditions.push(+new Date(c.updatedAt) > Date.now() - 2592000000);
            break;
          default:
        }

        if (filterText) {
          conditions.push(
            c.name?.toLowerCase().includes(filterText?.toLowerCase()),
          );
        }
        return conditions.every(Boolean);
      }),
    [fullConversationList, filters.time, filterText],
  );

  const conversationsPerUser = useMemo(
    () =>
      conversations.reduce(
        (acc, c) => {
          if (c.owner !== me?.id) acc.shared.push(c);
          else acc.recent.push(c);
          return acc;
        },
        { recent: [], shared: [] } as Record<string, Conversation[]>,
      ),
    [conversations, me?.id],
  );

  const handleSelectConvo = (convo: Conversation) => {
    if (convo.id != activeConversation?.id) {
      setCleanConversationDataSources(true);
      goToConversation(convo);
    }
  };

  return (
    <Layout.Sider
      className={styles.sidebar}
      theme="light"
      width="calc(100cqw - 88px)"
    >
      <Flex
        align="center"
        gap={10}
        style={{
          height: "60px",
          padding: "0 20px",
        }}
      >
        <SearchInput
          onChange={(e) => setFilterText((e?.target as HTMLInputElement).value)}
        />
        <Button
          icon={
            <FunnelIcon height={18} style={{ verticalAlign: "-0.25rem" }} />
          }
          onClick={() => setFiltersOpen((open) => !open)}
          type="text"
          style={{ width: "36px" }}
        />
      </Flex>

      <SidebarFilters isOpen={filtersOpen} onFilter={setFilters} />

      {isLoading ? (
        Array.from({ length: 6 }).map((_, idx) => (
          <Skeleton
            active
            className={styles.skeleton}
            key={idx}
            paragraph={{ rows: 1 }}
          />
        ))
      ) : filterText && !conversations.length ? (
        <Flex align="center" className={styles.empty} justify="center">
          <Flex align="center" gap="10px" className={styles.iconText}>
            <InboxIcon height="24px" /> No conversations.
          </Flex>
        </Flex>
      ) : (
        <Menu
          selectedKeys={[`${conversationId}`]}
          className={classNames(styles.menu, {
            [styles.filtersOpen]: filtersOpen,
          })}
        >
          <AnimatePresence>
            {Object.entries(conversationsPerUser).map(([section, convos]) => (
              <motion.li
                key={section}
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, x: -20 }}
                layout
                transition={{ duration: 0.2, ease: "easeInOut" }}
              >
                <Menu.ItemGroup className={cx(styles.sidebarHeader)}>
                  <Flex justify="space-between">
                    {section === "recent"
                      ? "Recent Conversations"
                      : "Shared with me"}
                  </Flex>
                </Menu.ItemGroup>
                {convos.map((conv: Conversation, index) => (
                  <SidebarItem
                    active={conversationId === `${conv.id}`}
                    conversation={conv}
                    disabled={isLoading}
                    index={index}
                    key={`convo-${conv.id}-${index}`}
                    onSelect={() => handleSelectConvo(conv)}
                  />
                ))}
              </motion.li>
            ))}
            {showLoadMoreConversations ? (
              <Flex justify="center">
                <Button
                  style={{
                    alignSelf: "center",
                    boxShadow: "none",
                    display: "inline-flex",
                    alignItems: "center",
                    marginBottom: "20px",
                  }}
                  disabled={additionalConversationsLoading}
                  onClick={loadMoreConversations}
                >
                  Load More
                </Button>
              </Flex>
            ) : null}
          </AnimatePresence>
        </Menu>
      )}
    </Layout.Sider>
  );
};
