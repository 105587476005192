import { message } from "antd";

export const copyToClipboard = (
  text: string,
  successMessage?: string,
  errorMessage?: string,
) => {
  if (text) {
    navigator.clipboard
      .writeText(text)
      .then(() => message.success(successMessage || "Copied to clipboard!"))
      .catch(() => message.error(errorMessage || "Failed to copy"));
  }
};
