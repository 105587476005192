import { useState, useCallback, useEffect, useMemo } from "react";
import { NewtonApi } from "utils/newtonApi";

// Global cache for display objects
const displayObjectCache = new Map<number, unknown>();

export function useDisplayObject(
  displayObject?: DisplayObject | DisplayObject[],
): unknown {
  const displayObjects = useMemo<DisplayObject[]>(
    () =>
      (Array.isArray(displayObject) ? displayObject : [displayObject!]).filter(
        (x) => x?.id,
      ),
    [displayObject],
  );

  // Initialize state with cached data if available
  const initialData = useMemo(() => {
    const cachedResults = displayObjects.map((obj) =>
      displayObjectCache.has(obj.id) ? displayObjectCache.get(obj.id) : null,
    );

    // Return cached data if available, otherwise null
    return cachedResults.every((result) => result !== null)
      ? displayObjects.length === 1
        ? cachedResults[0]
        : cachedResults
      : null;
  }, [displayObjects]);

  const [data, setData] = useState<boolean | unknown>(initialData);

  const fetch = useCallback(async () => {
    if (!displayObjects.length) return;

    try {
      const response = await Promise.all(
        displayObjects.map(async (obj) => {
          if (displayObjectCache.has(obj.id)) {
            return displayObjectCache.get(obj.id);
          } else {
            const result = await NewtonApi.fetchDisplayObject(obj);
            displayObjectCache.set(obj.id, result); // Cache the result
            return result;
          }
        }),
      );
      setData(displayObjects.length === 1 ? response[0] : response);
    } catch (e) {
      setData(false);
      console.error("Error fetching display objects", e);
    }
  }, [displayObjects]);

  useEffect(() => {
    if (displayObjects.length && data === null) {
      fetch();
    }
  }, [fetch, displayObjects, data]);

  if (!displayObjects.length) return false;

  return { data, displayObjects, fetch };
}
