import { DatabaseOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Dropdown,
  Flex,
  Skeleton,
  Tabs,
  Tooltip,
  Typography,
} from "antd";
import { useConversationContext } from "contexts/ConversationContext";
import { useDataProviderContext } from "contexts/DataProviderContext";
import React, { useMemo, useState } from "react";
import InfoIcon from "../../assets/icons/info.svg?react";
import AddIcon from "../../assets/icons/plus.svg?react";
import styles from "./ConversationSidePanel.module.scss";
import { SelectExistingDatasourceModal } from "./SelectExistingDatasourceModal";
import { UploadDatasourceModal } from "./UploadDatasourceModal";
import { showUserActions } from "utils/showUserActions";
import { useLocation } from "react-router-dom";

export const ConversationSidePanel: React.FC = () => {
  //Hidden until we have a definition about this action
  // const navigate = useNavigate();
  const [selectDatasource, setSelectDatasource] = useState(false);
  const { activeConversation, isLoading } = useDataProviderContext();
  const { selectedDataSources, addDatasource, setAddDatasource } =
    useConversationContext();
  const location = useLocation();
  const showButton = showUserActions(location.pathname);

  const convoDatasources = useMemo(() => {
    if (!activeConversation) return selectedDataSources;

    const combinedDatasources = [
      ...activeConversation.dataSources,
      ...selectedDataSources,
    ];

    const uniqueDatasourcesMap = new Map(
      combinedDatasources.map((item) => [item.id, item]),
    );
    return Array.from(uniqueDatasourcesMap.values());
  }, [activeConversation, selectedDataSources]);

  //Hidden until we have a definition about this action
  // const newConvo = (dataSource: DataSource) => {
  //   navigate(`/c/?datasource=${dataSource.id}`);
  // };

  const handleMenuClick = ({ key }: { key: string }) => {
    if (key === "upload") {
      setAddDatasource(true);
    } else if (key === "existing") {
      setSelectDatasource(true);
    }
  };

  const primaryColor = "#463e6b";

  return (
    <Flex justify="space-between" style={{ height: "100%" }} vertical>
      <Tabs
        items={[
          {
            key: "datasources",
            label: "Data sources",
            children: (
              <Flex gap="10px" vertical>
                {isLoading
                  ? Array.from({ length: 10 }).map((_, idx) => (
                      <Skeleton.Button
                        active
                        key={idx}
                        style={{ marginTop: "10px", width: "80%" }}
                      />
                    ))
                  : convoDatasources?.map((ds) => {
                      return (
                        <Card className={styles.card} key={ds!.id}>
                          <Flex
                            gap="20px"
                            justify="space-between"
                            style={{ width: "100%" }}
                          >
                            <Flex
                              align="center"
                              flex={1}
                              style={{
                                overflow: "hidden",
                              }}
                            >
                              <Typography.Paragraph
                                ellipsis
                                style={{ color: primaryColor, marginBottom: 0 }}
                              >
                                {ds!.name}
                              </Typography.Paragraph>
                            </Flex>
                            <Flex align="center" gap="5px">
                              {/*Hidden until we have a definition about this action*/}
                              {/* <ExportOutlined
                                twoToneColor={primaryColor}
                                height={16}
                                onClick={() => newConvo(ds)}
                              /> */}
                              <Tooltip title={ds!.description}>
                                <InfoIcon
                                  fill="var(--nri-color-primary-light-bg)"
                                  height={16}
                                />
                              </Tooltip>
                            </Flex>
                          </Flex>
                        </Card>
                      );
                    })}
              </Flex>
            ),
          },
        ]}
      />
      {showButton && (
        <div>
          {addDatasource && (
            <UploadDatasourceModal
              visible={addDatasource}
              onClose={() => setAddDatasource(false)}
            />
          )}
          {selectDatasource && (
            <SelectExistingDatasourceModal
              visible={selectDatasource}
              onClose={() => setSelectDatasource(false)}
            />
          )}
          <Dropdown
            menu={{
              onClick: handleMenuClick,
              items: [
                {
                  label: (
                    <>
                      <DatabaseOutlined /> Select from existing data sources
                    </>
                  ),
                  key: "existing",
                },
                {
                  label: (
                    <>
                      <UploadOutlined /> Upload new data source
                    </>
                  ),
                  key: "upload",
                },
              ],
            }}
          >
            <Button
              ghost
              icon={<AddIcon color="#695ad9" height={20} />}
              style={{
                border: "none",
                display: "flex",
                fontSize: 14,
                fontWeight: 500,
              }}
              type="primary"
            >
              Add data source
            </Button>
          </Dropdown>
        </div>
      )}
    </Flex>
  );
};
