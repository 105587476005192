import { Dropdown, Flex, Menu, message } from "antd";
import { useDataProviderContext } from "contexts/DataProviderContext";
import React from "react";
import { useNavigate } from "react-router-dom";
import ArchiveIcon from "../../assets/icons/archive.svg?react";
import ChatIcon from "../../assets/icons/chat.svg?react";
import CopyIcon from "../../assets/icons/copy.svg?react";
import EditIcon from "../../assets/icons/edit.svg?react";
import MenuIcon from "../../assets/icons/menu.svg?react";
// import ShareIcon from '../../assets/icons/shareHand.svg?react';
import TrashIcon from "../../assets/icons/trash.svg?react";
import useConfirmationModal from "hooks/useConfirmationModal";
const iconProps = {
  style: { color: "#918CA6" },
  width: "16px",
  height: "16px",
};

type Props = {
  template: IceBreaker;
};

export const ConversationTemplateActions: React.FC<Props> = ({ template }) => {
  const navigate = useNavigate();
  const {
    archiveIceBreaker,
    copyIceBreaker,
    deleteIceBreaker,
    startConversation,
    me,
  } = useDataProviderContext();

  const {
    showModal: showDeletionModal,
    ConfirmationModal: ConfirmationDeletionModal,
  } = useConfirmationModal(
    {
      title: "Delete template",
      content: "Are you sure you want to delete this template?",
    },
    () => {
      deleteIceBreaker(template);
      message.success("Template has been deleted");
    },
  );
  const {
    showModal: showArchiveModal,
    ConfirmationModal: ConfirmationArchiveModal,
  } = useConfirmationModal(
    {
      title: "Archive template",
      content: "Are you sure you want to archive this template?",
      primaryButtonText: "Yes, archive",
    },
    () => {
      archiveIceBreaker(template);
      template;
      message.success("Template has been archived");
    },
  );

  const items = [
    {
      key: "0",
      icon: <EditIcon {...iconProps} />,
      label: "Edit",
      hidden: template.owner != me?.id,
      onclick: () => {
        navigate(`/templates/edit/${template.id}`);
      },
    },
    {
      key: "1",
      icon: <CopyIcon {...iconProps} />,
      label: "Copy",
      onclick: async () => {
        const newTemplate = await copyIceBreaker(
          template as unknown as UpdateIceBreaker,
        );
        message.success("Template successfully copied");
        navigate(`/templates/edit/${newTemplate.id}`);
      },
    },
    {
      key: "2",
      icon: <ChatIcon {...iconProps} />,
      label: "Start a new conversation",
      onclick: () => {
        startConversation({ icebreaker: String(template.id) });
      },
    },
    // Share endpoitn not yet implemented
    // {
    //     key: '3',
    //     icon: <ShareIcon {...iconProps} />,
    //     label: 'Manage access',
    //     href: '#'
    // },
    // Archive endpoint not yet implemented
    {
      key: "4",
      icon: <ArchiveIcon {...iconProps} />,
      label: "Archive",
      onclick: () => {
        showArchiveModal();
      },
    },
    {
      key: "5",
      icon: <TrashIcon {...iconProps} />,
      label: "Delete",
      onclick: () => {
        showDeletionModal();
      },
    },
  ];

  const menu = (
    <Menu>
      {items.map((item) => (
        <Menu.Item
          key={item.key}
          style={{ display: `${item.hidden ? "none" : "inherit"}` }}
        >
          <a
            href="#"
            type="text"
            onClick={(e: React.MouseEvent) => {
              e.stopPropagation();
              e.preventDefault();
              item.onclick?.();
            }}
          >
            <Flex gap={8} align="center">
              <Flex>{item.icon}</Flex>
              <Flex>{item.label}</Flex>
            </Flex>
          </a>
        </Menu.Item>
      ))}
    </Menu>
  );
  return (
    <>
      <Dropdown
        overlay={menu}
        trigger={["click"]}
        // @ts-expect-error onclick required
        onClick={(e: React.MouseEvent) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <MenuIcon style={{ margin: "8px" }} />
      </Dropdown>

      {/* The card clicks propogate up so we need to stop it when clicking in the modal */}
      <Flex
        onClick={(e: React.MouseEvent) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <ConfirmationArchiveModal />
        <ConfirmationDeletionModal />
      </Flex>
    </>
  );
};
