import { useState, useEffect, useCallback } from "react";

export const useAutoScroll = (
  divRef: React.RefObject<HTMLDivElement>,
  dependencies: unknown[] = [],
) => {
  const [isUserAtBottom, setIsUserAtBottom] = useState(true);
  const [isAutoScrollEnabled, setIsAutoScrollEnabled] = useState(true);

  // Check if the user is at or near the bottom
  const handleScroll = useCallback(() => {
    if (!divRef.current) return;
    const { scrollTop, scrollHeight, clientHeight } = divRef.current;
    const isAtBottom = scrollHeight <= scrollTop + clientHeight + 1;

    // User scrolled away from the bottom, disable auto scroll
    if (!isAtBottom && isAutoScrollEnabled) {
      setIsAutoScrollEnabled(false);
    }

    // User is back at the bottom, re-enable auto scroll
    if (isAtBottom && !isAutoScrollEnabled) {
      setIsAutoScrollEnabled(true);
    }

    setIsUserAtBottom(isAtBottom);
  }, [divRef, isAutoScrollEnabled]);

  useEffect(() => {
    const attachScrollHandler = () => {
      if (divRef.current) {
        divRef.current.addEventListener("scroll", handleScroll);
      } else {
        // On initial page load the div may not be ready so we need to re-run the check until it's available.
        requestAnimationFrame(attachScrollHandler);
      }
    };

    attachScrollHandler();

    return () => {
      if (divRef.current) {
        divRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [divRef, handleScroll]);

  useEffect(() => {
    const div = divRef.current;
    if (div && isUserAtBottom && isAutoScrollEnabled) {
      div.scrollTop = div.scrollHeight;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [divRef, isUserAtBottom, isAutoScrollEnabled, ...dependencies]);

  const jumpToBottom = useCallback(() => {
    setIsAutoScrollEnabled(true);
    if (divRef.current) {
      divRef.current.scrollTop = divRef.current.scrollHeight;
      setIsUserAtBottom(true);
    }
  }, [divRef]);

  return { isUserAtBottom, isAutoScrollEnabled, jumpToBottom };
};
