import { Button } from "antd";
import AddIcon from "../../assets/icons/plus.svg?react";
import { useNavigate } from "react-router-dom";

export const NewConversationButton: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Button
      icon={<AddIcon height={24} />}
      size="large"
      type="primary"
      onClick={() => navigate("/c")}
      style={{ display: "flex" }}
    >
      New Conversation
    </Button>
  );
};
