import { Checkbox, Flex, Modal, Select, Space } from "antd";
import Avatar from "antd/es/avatar/avatar";
import Typography from "antd/es/typography/Typography";
import { useDataProviderContext } from "contexts/DataProviderContext";
import { useEffect, useState } from "react";
import { SelectedUser } from "./SelectedUser";
import styles from "./ShareConversationModal.module.scss";

type Props = {
  conversation: Conversation;
  isOpen: boolean;
  onClose(): void;
};

export const ShareConversationModal: React.FC<Props> = ({
  conversation,
  isOpen,
  onClose,
}) => {
  const { getConversationEntities, sharedEntities, shareConversation } =
    useDataProviderContext();

  const [entitiesOptions, setEntitiesOptions] =
    useState<ShareableEntityProps[]>(sharedEntities);
  const [loading, setLoading] = useState<boolean>();
  const [selectedUsers, setSelectedUsers] = useState<ShareableEntityProps[]>(
    [],
  );

  useEffect(() => {
    getConversationEntities(conversation.id).then((res) => {
      setSelectedUsers(res);
    });
  }, []);

  const isCheckedUser = (userId: string, type: string) => {
    const isChecked = selectedUsers?.some(
      (u) => u.id === userId && u.type === type,
    );
    if (isChecked) {
      return true;
    }
    return false;
  };

  const handleChangeUsers = (
    user: ShareableEntityProps,
    action: "add" | "remove",
  ) => {
    const alreadyExists = selectedUsers?.some(
      (u) => u.id === user.id && u.type === user.type,
    );

    if (action === "add" && !alreadyExists) {
      setSelectedUsers((prev: ShareableEntityProps[]) => [...prev, user]);
    }
    if (action === "remove" && alreadyExists) {
      setSelectedUsers((prev: ShareableEntityProps[]) =>
        prev.filter((u) => u !== user),
      );
    }
  };

  const handleConfirm = async () => {
    setLoading(true);
    await shareConversation(conversation.id, selectedUsers).finally(() => {
      setLoading(false);
      onClose();
    });
  };

  const handleSearch = (value: string) => {
    const filteredEntities = sharedEntities?.filter((entity) =>
      entity.name.toLowerCase().includes(value.toLowerCase()),
    );
    setEntitiesOptions(filteredEntities);
  };

  return (
    <Modal
      footer={(_, { CancelBtn, OkBtn }) => {
        return (
          <Flex justify="flex-end" gap="10px">
            <Space>
              <CancelBtn />
            </Space>
            <Space>
              <OkBtn />
            </Space>
          </Flex>
        );
      }}
      confirmLoading={loading}
      okText={"Save changes"}
      onCancel={onClose}
      onOk={handleConfirm}
      open={isOpen}
      title="Manage user access"
    >
      <Typography
        style={{ color: "#6A666E", fontSize: "16px", marginBottom: "40px" }}
      >
        Here you can configure other users' access to this conversation
      </Typography>
      <Select
        onSearch={handleSearch}
        dropdownRender={() => (
          <div className={styles.userList}>
            {entitiesOptions && entitiesOptions.length > 0 ? (
              entitiesOptions?.map(({ id, name, picture, type, ...rest }) => (
                <div className={styles.userItem} key={type + id}>
                  <Checkbox
                    checked={isCheckedUser(id, type)}
                    onChange={() =>
                      handleChangeUsers(
                        { name, picture, id, type, ...rest },
                        "add",
                      )
                    }
                  />
                  {picture !== "" ? (
                    <Avatar size={24} src={picture} />
                  ) : (
                    <Avatar className={styles.emptyAvatar} size={24}>
                      {name.split("")[0].toUpperCase()}
                    </Avatar>
                  )}
                  {name}
                </div>
              ))
            ) : (
              <div className={styles.userItem}>No user found</div>
            )}
          </div>
        )}
        mode="multiple"
        placeholder="Select user"
        className={styles.select}
      />

      <div className={styles.selectedUserContainer}>
        {selectedUsers?.map((user: ShareableEntityProps) => (
          <SelectedUser
            key={user.id + user.type}
            onDelete={(user, action: "add" | "remove") =>
              handleChangeUsers(user, action)
            }
            user={user}
          />
        ))}
      </div>
    </Modal>
  );
};
