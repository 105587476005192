import { Flex, Form, Radio, Space, Typography } from "antd";
import { Collapse } from "components/animations/Collapse";
import styles from "./Sidebar.module.scss";
import { useState } from "react";

export type SidebarFilters = {
  time?: "time-all" | "time-day" | "time-week" | "time-month";
};

type Props = {
  isOpen: boolean;
  onFilter(filters: SidebarFilters): void;
};

export const SidebarFilters: React.FC<Props> = ({ isOpen, onFilter }) => {
  // FIXME: fix how this filtering is handled. This is too hard-coded
  const [, setCurrentFilters] = useState<SidebarFilters>({});

  return (
    <Collapse isOpen={isOpen}>
      <Flex className={styles.filters} vertical>
        <div>
          <Typography.Text strong>Filters</Typography.Text>
        </div>
        <Form
          labelAlign="left"
          colon={false}
          onChange={(e: React.FormEvent<HTMLFormElement>) => {
            setCurrentFilters((f: SidebarFilters) => {
              const newFilters = { ...f };
              const val = (e.target as HTMLInputElement).value;
              if (val.startsWith("time")) {
                newFilters.time = val as SidebarFilters["time"];
              }

              onFilter(newFilters);
              return newFilters;
            });
          }}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Form.Item valuePropName="time">
            <Radio.Group defaultValue="time-all">
              <Space direction="vertical">
                <Radio value="time-all">All</Radio>
                <Radio value="time-day">Past 24 hours</Radio>
                <Radio value="time-week">Past week</Radio>
                <Radio value="time-month">Past month</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Flex>
    </Collapse>
  );
};
