import { Button, Flex, Input, Modal, Space } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";

import { formatDateWithTime } from "utils/formatDate";
import { useDataProviderContext } from "contexts/DataProviderContext";

type Props = {
  conversation: Conversation;
  isOpen: boolean;
  onClose(): void;
  onOk(): void;
};

export const ConversationModal: React.FC<Props> = ({
  conversation,
  isOpen,
  onClose,
  onOk,
}) => {
  const { deleteConversation, updateConversation } = useDataProviderContext();
  const [newTitle, setNewTitle] = useState<string>("");

  useEffect(() => {
    conversation?.name && setNewTitle(conversation?.name);
  }, [conversation]);

  return (
    <Modal
      title="Conversation Details"
      open={isOpen}
      onOk={() => {
        updateConversation({ ...conversation, name: newTitle });
        onOk();
      }}
      onCancel={onClose}
      footer={(_, { OkBtn, CancelBtn }) => (
        <Flex justify="space-between">
          <Space>
            <Button
              danger
              onClick={() => deleteConversation(conversation as Conversation)}
            >
              <DeleteOutlined />
            </Button>
          </Space>
          <Space>
            <CancelBtn />
            <OkBtn />
          </Space>
        </Flex>
      )}
      styles={{
        body: { padding: "20px" },
        footer: {
          padding: "20px",
        },
        header: { padding: "20px" },
      }}
    >
      <Input
        size="large"
        placeholder="Conversation Title"
        prefix={<EditOutlined />}
        value={newTitle}
        onChange={(e) => setNewTitle(e.target.value.substring(0, 30))}
      />
      This conversation was created on{" "}
      {formatDateWithTime(conversation.createdAt)}
    </Modal>
  );
};
