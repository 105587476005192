import { Dropdown } from "antd";
import { FC } from "react";

import type { MenuProps } from "antd";

type MemoryAdministratorDropdownProps = {
  button: JSX.Element;
  items: MenuProps["items"];
  handleAction: (event: string, feedback: FeedbackData) => void;
  feedback?: FeedbackData;
};

export const MemoryAdministratorDropdown: FC<
  MemoryAdministratorDropdownProps
> = ({ button, items, handleAction, feedback }) => {
  return (
    <Dropdown
      trigger={["click"]}
      menu={{
        items,
        onClick: (e) => handleAction(e.key, feedback as FeedbackData),
      }}
    >
      {button}
    </Dropdown>
  );
};
