import { Layout } from "antd";
import { FC } from "react";
import styles from "./Layout.module.scss";
import ConfigureData from "../components/configureData/configureData";

const ConfigureDataPage: FC = () => {
  return (
    <Layout className={styles.configureDataPage}>
      <ConfigureData />
    </Layout>
  );
};
export default ConfigureDataPage;
