import { Input } from "antd";
import SearchIcon from "../../assets/icons/search.svg?react";
import { debounce } from "utils/debounce";

export const SearchInput = ({
  onChange,
}: {
  onChange(e?: React.SyntheticEvent<HTMLInputElement>): void;
}) => {
  const debouncedFilter = debounce(onChange, 300);

  return (
    <Input
      // disabled={isLoading}
      prefix={<SearchIcon height={16} />}
      placeholder="Search"
      // If this search gets sent to the backend, may need to update
      onChange={(e) => debouncedFilter(e)}
      style={{ height: "37px", borderRadius: "10px", borderColor: "#DEDBE6" }}
    />
  );
};
