// ErrorNotification.jsx
import React from "react";
import { notification, Button, Tooltip } from "antd";
import { CloseOutlined, CopyOutlined } from "@ant-design/icons";
import { ApiError } from "../../utils/newtonApi";

const displayedErrors = new Set();
type DismissFunc = (error: ApiError) => void;

const openNotificationWithError = (error: ApiError, onDismiss: DismissFunc) => {
  if (displayedErrors.has(error.id)) {
    return;
  }
  displayedErrors.add(error.id);
  const truncatedMessage =
    error.message?.length > 300
      ? `${error.message.substring(0, 300)}...`
      : error.message;

  const handleCopy = () => {
    navigator.clipboard.writeText(error.message);
    notification.success({ message: "Error message copied to clipboard!" });
  };

  notification.error({
    message: `Error Occurred ${error.status ? `(${error.status})` : ""}`,
    description: (
      <div>
        <Tooltip title={error.message} overlayStyle={{ zIndex: 2060 }}>
          <span>{truncatedMessage}</span>
        </Tooltip>
      </div>
    ),
    btn: (
      <>
        <Button
          type="link"
          icon={<CopyOutlined />}
          onClick={handleCopy}
          style={{ marginLeft: 8 }}
        >
          Copy
        </Button>
        <Button
          type="primary"
          onClick={() => {
            onDismiss(error);
            notification.destroy(error.id); // close the notification on dismiss
          }}
          icon={<CloseOutlined />}
        >
          Dismiss
        </Button>
      </>
    ),
    key: error.id, // ensure each notification is unique
    duration: 0,
  });
};

export const ErrorNotification = ({
  errors,
  onDismiss,
}: {
  errors: ApiError[];
  onDismiss: DismissFunc;
}) => {
  React.useEffect(() => {
    errors.forEach((error) => {
      openNotificationWithError(error, onDismiss);
    });
  }, [errors, onDismiss]);

  return null;
};
