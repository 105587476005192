import { MagnifyingGlassIcon as SearchIcon } from "@heroicons/react/24/outline";
import { Button, Flex, Input, Skeleton, Typography } from "antd";
import { DataSourceList } from "components/datasource-list/DataSourceList";
import { PageSection } from "components/page-section/PageSection";
import { useConversationContext } from "contexts/ConversationContext";
import { useDataProviderContext } from "contexts/DataProviderContext";
import React, { useEffect, useMemo, useRef } from "react";
import { useLocation } from "react-router-dom";
import styles from "./BlankSlate.module.scss";

export const BlankSlate: React.FC = () => {
  const location = useLocation();
  const getQueryParams = (search: string) => {
    return new URLSearchParams(search);
  };
  const queryParams = getQueryParams(location.search);
  const datasource = queryParams.get("datasource");
  const {
    filteredDatasources: rawDatasources,
    isLoading,
    startConversation,
  } = useDataProviderContext();
  const { selectedDataSources, toggleSelectedDataSource: toggle } =
    useConversationContext();

  const memoizedToggle = useMemo(() => toggle, [toggle]);

  // Datasource corresponding to the URL param (if it exists)
  const memoizedDatasource = useMemo(() => {
    return rawDatasources?.results.find((d) => d.id.toString() === datasource);
  }, [datasource, rawDatasources]);

  const hasToggledRef = useRef(false);

  // Take the URL param for the data source and toggle it on once.
  useEffect(() => {
    if (!hasToggledRef.current && memoizedDatasource) {
      toggle(memoizedDatasource);
      hasToggledRef.current = true;
    }
  }, [memoizedDatasource, toggle]);

  return (
    <Flex justify="center" className={styles.blankSlate} vertical>
      <Flex align="center" justify="space-between">
        <div>
          <Typography.Title
            aria-hidden
            level={1}
            style={{
              color: "var(--nri-color-primary)",
              fontSize: "28px",
              fontWeight: "700",
              letterSpacing: "0.01em",
              lineHeight: "42px",
            }}
          >
            New Conversation
          </Typography.Title>
          <Typography.Title
            level={4}
            style={{
              fontSize: "16px",
              fontWeight: "400",
              lineHeight: "24px",
            }}
          >
            Select one or more data sources for your conversation with Newton.
            <br />
            You may also add data sources during the course of the conversation.
          </Typography.Title>
        </div>
        <div>
          <Button
            onClick={() => startConversation()}
            size="large"
            type="primary"
          >
            Start Conversation
          </Button>
        </div>
      </Flex>
      <div className={styles.content}>
        {isLoading ? (
          <Skeleton active={isLoading} />
        ) : (
          <PageSection
            button={
              <Input
                prefix={<SearchIcon height={16} />}
                placeholder="Search"
                // If this search gets sent to the backend, may need to update
                // onChange={(e) => debouncedFilter(e.target.value)}
              />
            }
            heading="Data sources"
            headingSmall={
              rawDatasources.loading === true || rawDatasources.loading === null
                ? "-"
                : String(rawDatasources.results.length)
            }
            subheading="Add one or more data sources"
          >
            <DataSourceList
              toggle={memoizedToggle}
              selected={selectedDataSources}
            />
          </PageSection>
        )}
      </div>
    </Flex>
  );
};
