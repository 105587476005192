import { createBrowserRouter } from "react-router-dom";
import { ErrorPage } from "./routes/ErrorPage";
import { App } from "./App";
import { ConversationPage } from "./routes/ConversationPage";
import { HomePage } from "./routes/HomePage";
import { TemplatePage } from "./routes/TemplatePage";
import Login from "components/login/Login";
import ProtectedRoute from "components/auth/Protect";
import Authorizing from "components/auth/Authorizing";
import { TemplatesPage } from "./routes/TemplatesPage";
import { MemoriesPage } from "./routes/MemoriesPage";
import { FeedbacksViewPage } from "./routes/FeedbacksViewPage";
import ConfigureDataPage from "./routes/ConfigureDataPage";

export const router = createBrowserRouter([
  {
    path: "/",
    errorElement: <ErrorPage />,
    children: [
      {
        element: <App />,
        children: [
          {
            index: true,
            // @TODO: Navigate to the last conversation
            element: (
              <ProtectedRoute>
                <HomePage />
              </ProtectedRoute>
            ),
          },
          {
            path: "c/",
            element: (
              <ProtectedRoute>
                <ConversationPage />
              </ProtectedRoute>
            ),
          },
          {
            path: "c/:conversationId",
            element: (
              <ProtectedRoute>
                <ConversationPage />
              </ProtectedRoute>
            ),
          },
          {
            path: "templates/new",
            element: (
              <ProtectedRoute>
                <TemplatePage />
              </ProtectedRoute>
            ),
          },
          {
            path: "templates/edit/:templateId",
            element: (
              <ProtectedRoute>
                <TemplatePage />
              </ProtectedRoute>
            ),
          },
          {
            path: "templates/",
            element: (
              <ProtectedRoute>
                <TemplatesPage />
              </ProtectedRoute>
            ),
          },
          {
            path: "memories",
            element: (
              <ProtectedRoute>
                <MemoriesPage />
              </ProtectedRoute>
            ),
          },
          {
            path: "memories/feedbacks/:conversationId",
            element: (
              <ProtectedRoute>
                <FeedbacksViewPage />
              </ProtectedRoute>
            ),
          },
          {
            path: "data-config/",
            element: (
              <ProtectedRoute>
                <ConfigureDataPage />
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: "unauthorized",
        element: <ErrorPage type="unauthorized" />,
      },
      {
        path: "login/",
        element: <Login />,
      },
      {
        path: "authorizing/",
        element: <Authorizing />,
      },
    ],
  },
]);
