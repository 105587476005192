import { useState, useCallback, useRef } from "react";

export function useList<T extends { id: Brand<string, ""> | number | string }>({
  singleSelect = false,
  initialSelection = [],
}: { singleSelect?: boolean; initialSelection?: T[] } = {}) {
  const initialSelectionRef = useRef(initialSelection);
  const [list, setList] = useState<T[]>([...initialSelectionRef.current]);

  const add = useCallback(
    (item: T) => {
      if (singleSelect) {
        setList([item]);
        return;
      }
      setList((prevList) => [...prevList, item]);
    },
    [singleSelect],
  );

  const inList = useCallback(
    (item: T) => {
      return list.some((i) => i.id === item.id);
    },
    [list],
  );

  const remove = useCallback((item: T) => {
    setList((prevList) => prevList.filter((i) => i.id !== item.id));
  }, []);

  const toggle = useCallback(
    (item: T) => {
      if (inList(item)) {
        remove(item);
      } else {
        add(item);
      }
    },
    [add, remove, inList],
  );

  const clear = useCallback(() => {
    setList([]);
  }, []);

  return [list, { add, clear, inList, remove, toggle }] as const;
}
