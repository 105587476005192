import { Button, Modal, Typography } from "antd";
import { FC } from "react";
import styles from "./ConfirmationModal.module.scss";

type ConfirmationModalProps = {
  isOpen: boolean;
  confirmationButton?: string;
  cancelButton?: string;
  title: string;
  description: string;
  onCancel: () => void;
  onConfirm: () => void;
  confirmColor: string;
};

export const ConfirmationModal: FC<ConfirmationModalProps> = ({
  confirmationButton,
  cancelButton,
  isOpen,
  onConfirm,
  onCancel,
  title,
  description,
  confirmColor,
}) => {
  return (
    <Modal
      onOk={onConfirm}
      title={title}
      onCancel={onCancel}
      open={isOpen}
      footer={[
        <Button type="default" onClick={onCancel}>
          {cancelButton || "Cancel"}
        </Button>,
        <Button
          style={{ backgroundColor: `${confirmColor}` }}
          onClick={onConfirm}
          type="primary"
        >
          {confirmationButton || "Confirm"}
        </Button>,
      ]}
    >
      <Typography.Text className={styles.description}>
        {description}
      </Typography.Text>
    </Modal>
  );
};
