import Flex from "antd/es/flex";
import styles from "./Layouts.module.scss";
import { PropsWithChildren } from "react";
import Typography from "antd/es/typography";
import Button from "antd/es/button";
import classnames from "classnames";
import ArrowLeft from "assets/icons/arrowLeft.svg?react";

type Props = PropsWithChildren & {
  buttonProps?: {
    children: React.ReactNode;
    onClick: () => void;
  };
  onBack?(): void;
  title: React.ReactNode;
  subtitle: React.ReactNode;
};

/**
 * Base Layout used in:
 * - Templates Page
 * TODO:
 * - Conversation Page
 * - Home Page
 */
export const BaseLayout: React.FC<Props> = ({
  buttonProps,
  children,
  onBack,
  subtitle,
  title,
}) => {
  return (
    <Flex
      className={classnames(styles.page, styles.baseLayout)}
      justify="center"
      vertical
    >
      <div className={styles.content}>
        <Flex align="center" className={styles.header} justify="space-between">
          <div>
            <Typography.Title className={styles.title} level={1}>
              {onBack && (
                <ArrowLeft
                  onClick={onBack}
                  style={{
                    color: "#918CA6",
                    cursor: "pointer",
                    marginRight: 12,
                  }}
                />
              )}
              {title}
            </Typography.Title>
            <Typography.Title
              className={styles.subtitle}
              level={4}
              style={{
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              {subtitle}
            </Typography.Title>
          </div>
          {buttonProps && (
            <div>
              <Button onClick={buttonProps.onClick} size="large" type="primary">
                {buttonProps.children}
              </Button>
            </div>
          )}
        </Flex>
        {children}
      </div>
    </Flex>
  );
};
