import { useAuth0 } from "@auth0/auth0-react";
import { Layout } from "antd";
import { Loader } from "components/loader/Loader";
import { Navigate } from "react-router-dom";

const Authorizing = () => {
  const { isAuthenticated, isLoading } = useAuth0();
  if (isAuthenticated) {
    return <Navigate to="/c" />;
  }

  if (!isLoading) {
    return <Navigate to="/login" />;
  }

  return (
    <Layout
      style={{ background: "white", height: "100vh", justifyContent: "center" }}
    >
      <Loader spinning={true} />
    </Layout>
  );
};

export default Authorizing;
