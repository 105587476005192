import { Button, Dropdown, MenuProps } from "antd";

import { FC } from "react";
import { MoreOutlined } from "@ant-design/icons";

type DropdownProps = {
  items: MenuProps["items"];
};
export const SidebarItemActionDropdown: FC<DropdownProps> = ({ items }) => {
  return (
    <Dropdown menu={{ items }} trigger={["click"]}>
      <Button
        style={{ width: "32px", height: "32px", padding: 0 }}
        onClick={(e) => e.preventDefault()}
      >
        <MoreOutlined style={{ width: "16px", height: "16px" }} />
      </Button>
    </Dropdown>
  );
};
