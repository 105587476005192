export function findKeysInLocalStorage(word: string) {
  const matchingKeys = [];

  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (key?.includes(word)) {
      matchingKeys.push(key);
    }
  }

  return matchingKeys;
}
