import { Flex, Form, Input, Modal, Select, Space } from "antd";
import Typography from "antd/es/typography/Typography";
import { useMemoriesContext } from "contexts/MemoriesContext";
import styles from "./MemoryModal.module.scss";
import { useState } from "react";

type Props = {
  isOpen: boolean;
  onClose(): void;
};

export const MemoryModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const { createMemory, sources, types } = useMemoriesContext();
  // const [selectedScope, setSelectedScope] = useState<number | undefined>(undefined);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedSource] = useState<number | undefined>(
    sources.filter((source) => source.name === "manual")[0].id,
  );
  const [selectedType, setSelectedType] = useState<number | undefined>(
    undefined,
  );
  const [question, setQuestion] = useState<string>("");
  const [response, setResponse] = useState<string>("");
  const [name, setName] = useState<string>("");

  return (
    <Modal
      footer={(_, { OkBtn, CancelBtn }) => (
        <Flex justify="flex-end">
          <Space>
            <CancelBtn />
            <OkBtn />
          </Space>
        </Flex>
      )}
      okText="Save"
      onCancel={onClose}
      onOk={async () => {
        // @TODO proper wiring
        await createMemory({
          index: question,
          name: name.substring(0, 30),
          source: selectedSource,
          type: selectedType,
          value: response,
        });
        onClose();
      }}
      open={isOpen}
      title="Create Memory"
      width={720}
    >
      <Typography
        style={{ color: "#6A666E", fontSize: "16px", marginBottom: "40px" }}
      >
        Create a question-response example for use with this workflow template
      </Typography>
      <div className={styles.formLayout}>
        <Form layout="vertical" className={styles.form}>
          <Form.Item
            label="Name"
            valuePropName="checked"
            className={styles.header}
          >
            <Input
              placeholder="Name"
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </Form.Item>
        </Form>
        <Form layout="vertical" className={styles.form}>
          <Form.Item
            label="Type"
            valuePropName="checked"
            className={styles.header}
          >
            <Select
              onSelect={async (type) => {
                setSelectedType(type);
              }}
              options={types.map((type) => ({
                label: type.name,
                value: type.id,
              }))}
              placeholder="Select Type"
              showSearch={false}
              value={selectedType}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Form>
        {/* <Form layout="vertical" className={styles.form}>
          <Form.Item label="Scope" valuePropName="checked" className={styles.header} >
            <Select
              onSelect={async (scope) => {
                setSelectedScope(scope);
              }}
              options={scopes.map((type) => ({ label: type.name, value: type.value }))}
              placeholder="Select Scope"
              showSearch={false}
              value={selectedScope}
              style={{ width: "100%" }}

            />
          </Form.Item>
        </Form> */}
      </div>

      <Form layout="vertical">
        {/* <Form layout="vertical" className={styles.form}>
          <Form.Item label="Source" valuePropName="checked" className={styles.header} >
            <Select
              onSelect={async (source) => {
                setSelectedSource(source);
              }}
              options={sources.map((type) => ({ label: type.name, value: type.id }))}
              placeholder="Select Source"
              showSearch={false}
              value={selectedSource}
              style={{ width: "100%" }}

            />
          </Form.Item>
        </Form> */}
        <Form.Item label="Question">
          <Input.TextArea
            placeholder="Input text"
            onChange={(e) => {
              setQuestion(e.target.value);
            }}
          />
        </Form.Item>
        <Form.Item label="Response">
          <Input.TextArea
            placeholder="Input text"
            autoSize={{ minRows: 15 }}
            onChange={(e) => {
              setResponse(e.target.value);
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
