type ActiveConversationAction = {
  type: "ACTIVE_CONVERSATION";
  payload: ConversationId;
};

type FetchAnalystsAction = {
  type: "FETCH_ANALYSTS";
  payload: Analyst[];
};

type FetchDefaultAnalyst = {
  type: "FETCH_DEFAULT_ANALYST";
  payload: Analyst;
};

type FetchConversationsAction = {
  type: "FETCH_CONVERSATIONS";
};

type FetchedAdditionalConversationsAction = {
  type: "FETCHED_ADDITIONAL_CONVERSATIONS";
  payload: {
    conversations: Conversation[];
    pagination: PaginationType;
  };
};

type FetchedConversationsAction = {
  type: "FETCHED_CONVERSATIONS";
  payload: {
    conversations: Conversation[];
    pagination: PaginationType;
  };
};

type FetchDataSourcesAction = {
  type: "FETCH_DATASOURCES";
  payload: DataSource[];
};

type ArchiveIceBreaker = {
  type: "ARCHIVE_ICEBREAKER";
  payload: IceBreaker["id"];
};

type DeleteIceBreakersAction = {
  type: "DELETE_ICEBREAKER";
  payload: IceBreaker["id"];
};

type FetchIceBreakersAction = {
  type: "FETCH_ICEBREAKERS";
  payload: IceBreaker[];
};

type FetchShareableEntitiesAction = {
  type: "FETCH_SHAREABLE_ENTITIES";
  payload: ShareableEntity[];
};

type FetchUserAction = {
  type: "FETCH_USER";
  payload: UserEntity;
};

type AddConversationAction = {
  type: "ADD_CONVERSATION";
  payload: Conversation;
};

type AddIceBreakerAction = {
  type: "ADD_ICEBREAKER";
  payload: IceBreaker;
};

type UpdatePartialConversationAction = {
  type: "UPDATE_PARTIAL_CONVERSATION";
  payload: Partial<Conversation>;
};

type UpdateConversationAction = {
  type: "UPDATE_CONVERSATION";
  payload: Conversation;
};

type UpdateMessageAction = {
  type: "UPDATE_MESSAGE";
  payload: { message: Message; conversationId: ConversationId };
};

type DeleteConversationAction = {
  type: "DELETE_CONVERSATION";
  payload: ConversationId;
};

type ArchiveConversationAction = {
  type: "ARCHIVE_CONVERSATION";
  payload: ConversationId;
};

type RewindConversationAction = {
  type: "REWIND_CONVERSATION";
  payload: number;
};

type NewMessageAction = {
  type: "NEW_MESSAGE";
  payload: Message;
};

type StartStreamingAction = {
  type: "STREAMING_START";
  payload: ConversationId;
};

type StreamingCompleteAction = {
  type: "STREAMING_COMPLETE";
  payload: ConversationId;
};

type UpdateIceBreakerAction = {
  type: "UPDATE_ICEBREAKER";
  payload: IceBreaker;
};

type UpdateActiveConversationAction = {
  type: "UPDATE_ACTIVE_CONVERSATION";
  payload: {
    conversations: Conversation[];
    activeConversation: Conversation;
  };
};

type FilterDataSourcesRequestAction = {
  type: "FILTER_DATASOURCES_REQUEST";
};

type FilterDataSourcesAction = {
  type: "FILTER_DATASOURCES";
  payload: DataSource[];
};

type FilterDataSourcesCleanAction = {
  type: "FILTER_DATASOURCES_CLEAN";
};

type DeleteDatasourceAction = {
  type: "DELETE_DATASOURCE";
  payload: number;
};

type FetchAllSentimentsRequestAction = {
  type: "FETCH_ALL_SENTIMENTS_REQUEST";
};

type FetchAllSentimentsAction = {
  type: "FETCH_ALL_SENTIMENTS";
  payload: Sentiment[];
};

type FetchAllSentimentsCleanAction = {
  type: "FETCH_ALL_SENTIMENTS_CLEAN";
};

type NewActiveConversationRequestAction = {
  type: "NEW_ACTIVE_CONVERSATION_REQUEST";
};

type NewActiveConversationAction = {
  type: "NEW_ACTIVE_CONVERSATION";
  payload: Conversation | undefined;
};

type NewActiveConversationCleanAction = {
  type: "NEW_ACTIVE_CONVERSATION_CLEAN";
};

type ConversationDataSourcesRequestAction = {
  type: "CONVERSATION_DATASOURCES_REQUEST";
};

type ConversationDataSourcesAction = {
  type: "CONVERSATION_DATASOURCES";
  payload: MessageDataSources[];
};

type ConversationDataSourcesCleanAction = {
  type: "CONVERSATION_DATASOURCES_CLEAN";
};

type UpdateConversationDataSourcesAction = {
  type: "UPDATE_CONVERSATION_DATASOURCES";
  payload: MessageDataSources;
};

type ConversationActions =
  | ActiveConversationAction
  | ArchiveIceBreaker
  | FetchAnalystsAction
  | FetchDefaultAnalyst
  | FetchConversationsAction
  | FetchedAdditionalConversationsAction
  | FetchedConversationsAction
  | FetchDataSourcesAction
  | DeleteIceBreakersAction
  | FetchIceBreakersAction
  | FetchShareableEntitiesAction
  | FetchUserAction
  | AddConversationAction
  | AddIceBreakerAction
  | UpdatePartialConversationAction
  | UpdateConversationAction
  | UpdateMessageAction
  | DeleteConversationAction
  | ArchiveConversationAction
  | RewindConversationAction
  | NewMessageAction
  | StartStreamingAction
  | StreamingCompleteAction
  | UpdateActiveConversationAction
  | FilterDataSourcesRequestAction
  | FilterDataSourcesAction
  | FilterDataSourcesCleanAction
  | DeleteDatasourceAction
  | UpdateIceBreakerAction
  | FetchAllSentimentsRequestAction
  | FetchAllSentimentsAction
  | FetchAllSentimentsCleanAction
  | NewActiveConversationRequestAction
  | NewActiveConversationAction
  | NewActiveConversationCleanAction
  | ConversationDataSourcesRequestAction
  | ConversationDataSourcesAction
  | ConversationDataSourcesCleanAction
  | UpdateConversationDataSourcesAction;

export const conversationReducer = (
  state: {
    activeConversationId?: ConversationId;
    activeStreams: { [key: ConversationId]: boolean };
    analysts: Analyst[];
    defaults: {
      analyst?: Analyst;
    };
    conversations: {
      data: Conversation[];
      loading: boolean;
      pagination: PaginationType;
    };
    datasources: DataSource[];
    filteredDatasources: { results: DataSource[]; loading: boolean | null };
    feedbackSentiments: { results: Sentiment[]; loading: boolean | null };
    loaded: boolean;
    iceBreakers: IceBreaker[];
    sharedEntities: ShareableEntity[];
    unread: { [key: ConversationId]: MessageId[] };
    user: UserEntity | undefined;
    newActiveConversation: {
      data: Conversation | undefined;
      loading: boolean | null;
    };
    conversationDatasources: {
      results: MessageDataSources[];
      loading: boolean | null;
    };
  },
  action: ConversationActions,
) => {
  switch (action.type) {
    case "ACTIVE_CONVERSATION":
      return {
        ...state,
        unread: {
          ...state.unread,
          [action.payload]: [],
        },
        activeConversationId: action.payload,
      };
    case "FETCH_ANALYSTS":
      return {
        ...state,
        analysts: action.payload,
      };
    case "FETCH_DEFAULT_ANALYST":
      return {
        ...state,
        defaults: {
          ...state.defaults,
          analyst: action.payload,
        },
      };
    case "FETCHED_ADDITIONAL_CONVERSATIONS": {
      const { conversations, pagination } = action.payload;

      return {
        ...state,
        loaded: true,
        conversations: {
          data: [...state.conversations.data, ...conversations],
          loading: false,
          pagination,
        },
      };
    }

    case "FETCH_CONVERSATIONS":
      return {
        ...state,
        conversations: {
          ...state.conversations,
          loading: true,
        },
      };

    case "FETCHED_CONVERSATIONS": {
      const { conversations, pagination } = action.payload;

      return {
        ...state,
        loaded: true,
        conversations: {
          data: conversations,
          loading: false,
          pagination,
        },
      };
    }
    case "FETCH_DATASOURCES":
      return {
        ...state,
        datasources: action.payload,
      };
    case "FETCH_ICEBREAKERS":
      return {
        ...state,
        iceBreakers: action.payload,
      };
    case "DELETE_ICEBREAKER":
      return {
        ...state,
        iceBreakers: state.iceBreakers.filter((ib) => ib.id !== action.payload),
      };
    case "FETCH_SHAREABLE_ENTITIES":
      return {
        ...state,
        sharedEntities: action.payload,
      };
    case "FETCH_USER":
      return {
        ...state,
        user: action.payload,
      };
    case "ADD_CONVERSATION":
      return {
        ...state,
        conversations: {
          ...state.conversations,
          data: [
            {
              ...action.payload,
              owner: state.user?.id,
            },
            ...state.conversations.data,
          ],
        },
      };

    case "ADD_ICEBREAKER":
      return {
        ...state,
        iceBreakers: [...state.iceBreakers, action.payload],
      };

    case "ARCHIVE_ICEBREAKER":
      return {
        ...state,
        iceBreakers: state.iceBreakers.filter((ib) => ib.id !== action.payload),
      };

    case "UPDATE_PARTIAL_CONVERSATION": {
      return {
        ...state,
        conversations: {
          ...state.conversations,
          data: state.conversations.data.map((convo) =>
            convo.id === action.payload.id
              ? { ...convo, name: action.payload.name }
              : convo,
          ),
        },
        newActiveConversation: {
          ...state.newActiveConversation,
          data: {
            ...(state.newActiveConversation.data as Conversation),
            ...action.payload,
          },
        },
      };
    }
    case "UPDATE_ICEBREAKER":
      return {
        ...state,
        iceBreakers: state.iceBreakers.map((ib) =>
          ib.id === action.payload.id ? action.payload : ib,
        ),
      };
    case "UPDATE_CONVERSATION":
      return {
        ...state,
        conversations: {
          ...state.conversations,
          data: state.conversations.data.map((convo) =>
            convo.id === action.payload.id
              ? { ...convo, name: action.payload.name }
              : convo,
          ),
        },
        newActiveConversation: {
          ...state.newActiveConversation,
          data: action.payload,
        },
      };
    case "DELETE_CONVERSATION":
      return {
        ...state,
        conversations: {
          ...state.conversations,
          data: state.conversations.data.filter(
            (convo) => convo.id != action.payload,
          ),
        },
      };
    case "ARCHIVE_CONVERSATION":
      return {
        ...state,
        conversations: {
          ...state.conversations,
          data: state.conversations.data.filter(
            (convo) => convo.id != action.payload,
          ),
        },
      };
    case "REWIND_CONVERSATION":
      return {
        ...state,
        newActiveConversation: {
          ...state.newActiveConversation,
          data: {
            ...(state.newActiveConversation.data as Conversation),
            messageSet: [
              ...(state.newActiveConversation.data?.messageSet.slice(
                0,
                action.payload,
              ) as Message[]),
            ],
          },
        },
      };
    case "STREAMING_START":
      return {
        ...state,
        activeStreams: {
          ...state.activeStreams,
          [action.payload]: true,
        },
      };
    case "NEW_MESSAGE":
      // eslint-disable-next-line no-case-declarations
      const unreadMessages = state.unread[action.payload.conversationId!] || [];

      if (
        action.payload.conversationId != state.activeConversationId &&
        action.payload.role !== "user"
      ) {
        unreadMessages.push(action.payload.id!);
      }

      return {
        ...state,
        activeStreams: {
          ...state.activeStreams,
          [action.payload.conversationId!]: true,
        },
        unread: {
          ...state.unread,
          [action.payload.conversationId!]: unreadMessages.filter(
            (v, i, a) => a.indexOf(v) === i,
          ),
        },
        newActiveConversation: {
          ...state.newActiveConversation,
          data:
            action.payload.conversationId ==
            state.newActiveConversation.data?.id
              ? {
                  ...(state.newActiveConversation.data as Conversation),
                  messageSet: [
                    ...(state.newActiveConversation.data?.messageSet.filter(
                      (m: Message) => !!m.id && m.id != action.payload.id,
                    ) as Message[]),
                    action.payload,
                  ],
                }
              : (state.newActiveConversation.data as Conversation),
        },
      };
    case "STREAMING_COMPLETE":
      // eslint-disable-next-line no-case-declarations
      const activeStreams = { ...state.activeStreams };
      delete activeStreams[action.payload];
      return {
        ...state,
        activeStreams,
      };
    case "UPDATE_ACTIVE_CONVERSATION":
      // eslint-disable-next-line no-case-declarations
      const { activeConversation } = action.payload;
      return {
        ...state,
        activePlaceholder: { ...activeConversation, owner: state.user?.id },
        newActiveConversation: {
          ...state.newActiveConversation,
          data: {
            ...(state.newActiveConversation.data as Conversation),
            ...action.payload,
          },
        },
      };
    case "FILTER_DATASOURCES_REQUEST":
      return {
        ...state,
        filteredDatasources: { ...state.filteredDatasources, loading: true },
      };
    case "FILTER_DATASOURCES":
      return {
        ...state,
        filteredDatasources: { results: action.payload, loading: false },
      };
    case "FILTER_DATASOURCES_CLEAN":
      return {
        ...state,
        filteredDatasources: { results: [], loading: null },
      };
    case "DELETE_DATASOURCE":
      return {
        ...state,
        filteredDatasources: {
          ...state.filteredDatasources,
          results: state.filteredDatasources.results.filter(
            (ds) => ds.id !== action.payload,
          ),
        },
      };
    case "FETCH_ALL_SENTIMENTS_REQUEST":
      return {
        ...state,
        feedbackSentiments: { ...state.feedbackSentiments, loading: true },
      };
    case "FETCH_ALL_SENTIMENTS":
      return {
        ...state,
        feedbackSentiments: { results: action.payload, loading: false },
      };
    case "FETCH_ALL_SENTIMENTS_CLEAN":
      return {
        ...state,
        feedbackSentiments: { results: [], loading: null },
      };
    case "UPDATE_MESSAGE":
      return {
        ...state,
        newActiveConversation: {
          ...state.newActiveConversation,
          data: {
            ...(state.newActiveConversation.data as Conversation),
            messageSet: state.newActiveConversation.data?.messageSet.map(
              (m: Message) =>
                m.id === action.payload.message.id ? action.payload.message : m,
            ) as Message[],
          },
        },
      };
    case "NEW_ACTIVE_CONVERSATION_REQUEST":
      return {
        ...state,
        newActiveConversation: {
          ...state.newActiveConversation,
          loading: true,
        },
      };
    case "NEW_ACTIVE_CONVERSATION":
      return {
        ...state,
        newActiveConversation: {
          data: action.payload,
          loading: false,
        },
      };
    case "NEW_ACTIVE_CONVERSATION_CLEAN":
      return {
        ...state,
        newActiveConversation: {
          data: undefined,
          loading: null,
        },
      };
    case "CONVERSATION_DATASOURCES_REQUEST":
      return {
        ...state,
        conversationDatasources: {
          ...state.conversationDatasources,
          loading: true,
        },
      };
    case "CONVERSATION_DATASOURCES":
      return {
        ...state,
        conversationDatasources: {
          results: action.payload,
          loading: false,
        },
      };
    case "CONVERSATION_DATASOURCES_CLEAN":
      return {
        ...state,
        conversationDatasources: {
          results: [],
          loading: null,
        },
      };

    case "UPDATE_CONVERSATION_DATASOURCES":
      return {
        ...state,
        conversationDatasources: {
          ...state.conversationDatasources,
          results: [...state.conversationDatasources.results, action.payload],
        },
        newActiveConversation: {
          ...state.newActiveConversation,
          data: {
            ...(state.newActiveConversation.data as Conversation),
            dataSources: [
              ...(state.newActiveConversation.data
                ?.dataSources as DataSource[]),
              ...action.payload.dataSources,
            ],
          },
        },
      };
    default:
      return state;
  }
};
