import { format, parseISO } from "date-fns";

export function formatDate(d: string) {
  const parsedDate = parseISO(d); // Parses the ISO string into a Date object
  return format(parsedDate, "MM.dd.yyyy"); // Formats the Date object as "MM.dd.yyyy"
}

export function formatDateWithTime(d: string) {
  const parsedDate = parseISO(d); // Parses the ISO string into a Date object
  return format(parsedDate, "MM.dd.yyyy 'at' pp");
}
