import { Flex, Typography } from "antd";

type PageSectionProps = {
  button?: React.ReactNode;
  children: React.ReactNode;
  heading?: string;
  headingSmall?: string;
  subheading?: string;
};

export const PageSection = ({
  button,
  children,
  heading,
  headingSmall,
  subheading,
}: PageSectionProps) => {
  return (
    <>
      <Flex align="center" justify="space-between">
        <div>
          <Typography.Title
            aria-hidden
            level={1}
            style={{
              color: "var(--nri-color-primary)",
              fontSize: "24px",
              fontWeight: "600",
              lineHeight: "30px",
              letterSpacing: "0.01em",
            }}
          >
            {heading}
            {headingSmall && (
              <Typography.Text
                style={{
                  color: "#695AD9",
                  fontSize: "20px",
                  lineHeight: "30px",
                  marginLeft: "10px",
                }}
              >
                {headingSmall}
              </Typography.Text>
            )}
          </Typography.Title>
          <Typography.Text
            style={{
              color: "#787878",
              fontSize: "16px",
              lineHeight: "24px",
            }}
          >
            {subheading}
          </Typography.Text>
        </div>
        <div>{button}</div>
      </Flex>
      {children}
    </>
  );
};
