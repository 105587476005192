import { Modal } from "antd";
import { ReactNode, useState } from "react";

const useConfirmationModal = (
  content: {
    title?: ReactNode;
    content: ReactNode;
    primaryButtonText?: string;
  },
  onConfirm: () => void,
) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setLoading(true);
    await onConfirm();
    setIsModalVisible(false);
    setLoading(false);
  };

  const handleCancel = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setIsModalVisible(false);
    throw new Error("Operation cancelled by user");
  };

  const ConfirmationModal = () => (
    <Modal
      title={content.title || "Confirmation"}
      visible={isModalVisible}
      onOk={handleOk}
      confirmLoading={loading}
      onCancel={handleCancel}
      okText={content?.primaryButtonText || "Yes, delete"}
      okButtonProps={{
        style: {
          backgroundColor: "var(--nri-color-danger)",
          borderColor: "var(--nri-color-danger)",
        },
      }}
      cancelText="Cancel"
    >
      {content.content}
    </Modal>
  );

  return {
    showModal,
    ConfirmationModal,
  };
};

export default useConfirmationModal;
