type FetchedMemoriesAction = {
  type: "FETCHED_MEMORIES";
  payload: Memory[];
};

type FetchedMemoryLabelsAction = {
  type: "FETCHED_MEMORY_LABELS";
  payload: MemoryLabel[];
};

type FetchedMemorySourcesAction = {
  type: "FETCHED_MEMORY_SOURCES";
  payload: MemorySource[];
};

type FetchedMemoryTypesAction = {
  type: "FETCHED_MEMORY_TYPES";
  payload: MemoryType[];
};

type SetLoadingAction = {
  type: "SET_LOADING";
  payload: boolean;
};

type MemoryActions =
  | FetchedMemoriesAction
  | FetchedMemoryLabelsAction
  | FetchedMemorySourcesAction
  | FetchedMemoryTypesAction
  | SetLoadingAction;

export const memoriesReducer = (
  state: {
    loading: boolean;
    labels: MemoryLabel[];
    memories: Memory[];
    sources: MemorySource[];
    types: MemoryType[];
  },
  action: MemoryActions,
) => {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        loading: action.payload,
      };
    case "FETCHED_MEMORIES":
      return {
        ...state,
        memories: action.payload,
      };
    case "FETCHED_MEMORY_LABELS":
      return {
        ...state,
        labels: action.payload,
      };

    case "FETCHED_MEMORY_SOURCES":
      return {
        ...state,
        sources: action.payload,
      };

    case "FETCHED_MEMORY_TYPES":
      return {
        ...state,
        types: action.payload,
      };
    default:
      return state;
  }
};
