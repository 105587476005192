import { useAuth0 } from "@auth0/auth0-react";
import { Avatar, Button, Card, Flex, Layout, Menu, Typography } from "antd";
import { NewConversationButton } from "components/new-converation-btn/NewConversationButton";
import { Sidebar } from "components/sidebar/Sidebar";
import { useLocation, useNavigate } from "react-router-dom";
import AddNoteIcon from "../../assets/icons/addNote.svg?react";
import CogIcon from "../../assets/icons/cog.svg?react";
import ConversationIcon from "../../assets/icons/fileText.svg?react";
import HomeIcon from "../../assets/icons/home.svg?react";
import LogoutIcon from "../../assets/icons/logout.svg?react";
import NotesIcon from "../../assets/icons/notes.svg?react";
import AddIcon from "../../assets/icons/plus.svg?react";
import SupportIcon from "../../assets/icons/question.svg?react";
import ConfigureIcon from "../../assets/icons/threeSquares.svg?react";
import TeamIcon from "../../assets/icons/userGroup.svg?react";
import LogoText from "../../assets/logotext3.svg?react";
import { cx } from "../../utils/cx";
import styles from "./MainMenu.module.scss";
import { useEffect, useState } from "react";
import { useDataProviderContext } from "contexts/DataProviderContext";

// @FIXME: Add the correct paths for the Dashboard and Templates menu item

export const MainMenu = () => {
  const { user, isLoading, logout } = useAuth0();

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const showSidebar = pathname.includes("/c");
  const [selectedPathname, setSelectedPathname] = useState(pathname);
  const { me } = useDataProviderContext();

  const icons = [
    {
      icon: <HomeIcon />,
      path: "/",
      title: "Home",
    },
    { icon: <ConversationIcon />, path: "/c", title: "Conversations" },
    {
      disabled: me?.flags?.uploadCredentials ? false : true,
      icon: <ConfigureIcon />,
      path: "/data-config",
      title: "Configure Data",
    },
    { disabled: true, icon: <TeamIcon />, path: "/team", title: "Team Admin" },
    {
      disabled: true,
      icon: <CogIcon />,
      path: "/settings",
      title: "User Settings",
    },
    { icon: <NotesIcon />, path: "/memories", title: "Memories" },
    {
      disabled: true,
      icon: <AddNoteIcon />,
      path: "/schedule",
      title: "Schedule Work",
    },
  ];

  useEffect(() => {
    if (pathname.includes("/c")) {
      setSelectedPathname("/c");
    } else {
      setSelectedPathname(pathname);
    }
  }, [pathname]);

  if (!user) return null;

  return (
    <Layout.Sider className={cx(styles.sider)} theme="light" width="unset">
      <Flex className={styles.mainMenu} vertical>
        <Flex className={styles.logo} justify="flex-start">
          <LogoText />
        </Flex>
        <Flex>
          <Layout.Sider
            className={cx(styles.iconToolbar, {
              [styles.collapsed]: showSidebar,
            })}
            collapsed={showSidebar}
            collapsedWidth={88}
            width="100%"
            theme="light"
          >
            <Flex
              align="center"
              className={styles.siderInner}
              justify="space-between"
              vertical
            >
              <div className={styles.siderTop}>
                <Menu
                  className={styles.menu}
                  selectedKeys={[selectedPathname]}
                  items={icons.map(({ disabled, icon, path, title }) => ({
                    className: cx(styles.item, {
                      [styles.active]: path === pathname,
                      [styles.disabled]: disabled,
                    }),
                    disabled,
                    icon,
                    key: path,
                    onClick: () => navigate(path),
                    label: title,
                    title: disabled ? `${title} coming soon` : title,
                  }))}
                ></Menu>
              </div>
              <div className={styles.siderBottom}>
                {isLoading ? (
                  <></>
                ) : (
                  <>
                    {showSidebar ? (
                      <Flex
                        align="center"
                        justify="center"
                        style={{ marginBottom: "24px" }}
                      >
                        <Button
                          icon={<AddIcon height={20} />}
                          onClick={() => navigate("/c")}
                          type="primary"
                          style={{ height: "44px", width: "44px" }}
                        />
                      </Flex>
                    ) : (
                      <Card bordered={false} className={styles.newConversation}>
                        <Flex gap="12px" vertical>
                          <Typography.Title level={4}>
                            New Conversation
                          </Typography.Title>
                          <Typography.Text style={{ marginBottom: 24 }}>
                            What can we
                            <br />
                            discover together?
                          </Typography.Text>
                        </Flex>
                        <NewConversationButton />
                      </Card>
                    )}
                    <Menu
                      className={styles.menu}
                      defaultSelectedKeys={["1"]}
                      items={[
                        {
                          disabled: true,
                          icon: <Avatar size={32} src={user?.picture} />,
                          title: "Account",
                          onClick: () => {},
                        },
                        {
                          disabled: true,
                          icon: <SupportIcon />,
                          title: "Support",
                          onClick: () => {},
                        },
                        {
                          icon: <LogoutIcon />,
                          title: "Logout",
                          onClick: () => {
                            logout({
                              logoutParams: {
                                returnTo: window.location.origin,
                              },
                            });
                          },
                        },
                      ].map(({ disabled, icon, title, onClick }, idx) => ({
                        className: cx(styles.item, {
                          [styles.disabled]: disabled,
                        }),
                        onClick,
                        disabled,
                        icon,
                        key: idx,
                        label: title,
                        title,
                      }))}
                    ></Menu>
                  </>
                )}
              </div>
            </Flex>
          </Layout.Sider>
          {showSidebar && <Sidebar />}
        </Flex>
      </Flex>
    </Layout.Sider>
  );
};
