import React, { ComponentPropsWithoutRef } from "react";
import { motion } from "framer-motion";

type Props = Omit<
  ComponentPropsWithoutRef<"div">,
  "onAnimationStart" | "onDragStart" | "onDragEnd" | "onDrag" | "onAnimationEnd"
> & {
  isOpen: boolean;
};

const variants = {
  open: { opacity: 1, height: "auto" },
  collapsed: { opacity: 1, height: 0 },
};

export const Collapse: React.FC<Props> = ({ isOpen, style, ...props }) => (
  <motion.div
    initial="collapsed"
    animate={isOpen ? "open" : "collapsed"}
    variants={variants}
    transition={{ duration: 0.2 }}
    style={{ ...style, overflow: "hidden" }}
    {...props}
  />
);
