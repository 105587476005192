import {
  CloudUploadOutlined,
  EditOutlined,
  EllipsisOutlined,
  SettingOutlined,
  ZoomInOutlined,
} from "@ant-design/icons";
import { CommandLineIcon } from "@heroicons/react/24/outline";
import { Button, Card, Col, Collapse, Flex, Image, message } from "antd";
import { useDisplayObject } from "hooks/useDisplayObject";
import { Highlight, themes } from "prism-react-renderer";
import { PropsWithChildren, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { NewtonApi } from "utils/newtonApi";
import NotesIcon from "../../assets/icons/notes.svg?react";
import DownloadIcon from "../../assets/icons/download.svg?react";
import styles from "./Message.module.scss";
import { useDataProviderContext } from "contexts/DataProviderContext";
import { Loader } from "components/loader/Loader";
import { useConversationContext } from "contexts/ConversationContext";

type Props = PropsWithChildren & { displayObject: DisplayObject };

export const DisplayObject: React.FC<Props> = ({ displayObject }) => {
  const { downloadFileByDisplayObject } = useDataProviderContext();
  const { setAddDatasource, setSelectedCSV } = useConversationContext();
  const [showIcons, setShowIcons] = useState<boolean>(false);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const { data } = useDisplayObject(displayObject) as { data: string };
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);

  const handleDownloadFile = async () => {
    await downloadFileByDisplayObject(displayObject);
    message.config({
      top: 600,
      duration: 4,
    });
    message.success("File downloaded");
  };

  const handleDownloadCSV = async (displayObject: DisplayObject) => {
    setIsDownloading(true);
    await NewtonApi.downloadDisplayObject(displayObject);
    setIsDownloading(false);
  };

  const handleCreateCSV = useCallback(
    async (displayObject: DisplayObject) => {
      const res = await NewtonApi.createCSV(displayObject);
      setAddDatasource(true);
      setSelectedCSV({ csv: res as File, displayObjectId: displayObject.id });
    },
    [setAddDatasource, setSelectedCSV],
  );

  const renderCard = () => {
    switch (displayObject.type) {
      case "png":
      case "jpg":
      case "jpeg":
      case "gif":
        return (
          <div
            onMouseOver={() => setShowIcons(true)}
            onMouseLeave={() => setShowIcons(false)}
          >
            {showIcons && (
              <div className={styles.buttonContainer}>
                <Button
                  className={styles.actionButton}
                  onClick={handleDownloadFile}
                >
                  <DownloadIcon />
                </Button>
                <Button
                  className={styles.actionButton}
                  onClick={() => setVisible(true)}
                >
                  <ZoomInOutlined style={{ fontSize: "20px" }} />
                </Button>
              </div>
            )}
            {data ? (
              <Image
                src={data}
                preview={{
                  visible,
                  mask: null,
                  imageRender() {
                    return (
                      <img
                        src={data}
                        style={{
                          height: "calc(80%)",
                          width: "calc(80%)",
                          objectFit: "contain",
                        }}
                      />
                    );
                  },
                  toolbarRender: () => null,
                  onVisibleChange: () => setVisible(false),
                }}
                onError={(e) => {
                  console.error("Error loading image", e);
                  navigate("/login");
                }}
              />
            ) : null}
          </div>
        );
      case "csv":
        return (
          <Flex
            align="center"
            className={styles.downloadButton}
            justify="space-between"
          >
            <Flex align="center">
              <NotesIcon style={{ marginRight: "10px" }} /> {displayObject.name}
            </Flex>
            <Flex gap={12}>
              <CloudUploadOutlined
                onClick={() => handleCreateCSV(displayObject)}
                style={{ fontSize: "24px", color: "#695AD9", opacity: 0.9 }}
              />
              {isDownloading ? (
                <Loader size="default" />
              ) : (
                <DownloadIcon
                  onClick={() => handleDownloadCSV(displayObject)}
                  color="#695AD9"
                />
              )}
            </Flex>
          </Flex>
        );
      case "python":
        return (
          <Card
            actions={[
              <SettingOutlined key="setting" />,
              <EditOutlined key="edit" />,
              <EllipsisOutlined key="ellipsis" />,
            ]}
            bordered={false}
          >
            <Card.Meta
              avatar={<CommandLineIcon height="24px" />}
              title="View Code"
              description={displayObject.name}
            />
            <Collapse defaultActiveKey={["1"]} ghost>
              <Collapse.Panel header="Expand" key="1">
                <Highlight
                  theme={themes.duotoneLight}
                  code={data}
                  language="python"
                >
                  {({ style, tokens, getLineProps, getTokenProps }) => (
                    <pre className={styles.code} style={style}>
                      {tokens.map((line, i) => (
                        <div key={i} {...getLineProps({ line })}>
                          {line.map((token, key) => (
                            <span key={key} {...getTokenProps({ token })} />
                          ))}
                        </div>
                      ))}
                    </pre>
                  )}
                </Highlight>
              </Collapse.Panel>
            </Collapse>
          </Card>
        );
      default:
        return <>{JSON.stringify(displayObject)}</>;
    }
  };

  return <Col span={24}>{renderCard()}</Col>;
};

export default DisplayObject;
